import { useState, useEffect } from 'react';
import compareObj from '../utils/compareObj';

export default (initialValue, key, options) => {

  const initData = (!!options && options.action === 'force') ? initialValue :
    localStorage.getItem(`evidenceB-${key}`)?
    JSON.parse(localStorage.getItem(`evidenceB-${key}`)) : initialValue;

  const [data, action] = useState(initData);

  useEffect(() => {
    if (localStorage && data && (!initData || initData && !compareObj(data, initData))) {
      // console.log('storeData', key, data);
      localStorage.setItem(`evidenceB-${key}`, JSON.stringify(data));
      action(data);
    }
  });
  return [data, action];
}
