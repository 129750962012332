import React, { useState, useEffect, useRef } from 'react';
import uniqid from 'uniqid';
import '../scss/HighlightText.scss'
function HighlightText(props) {

    const [state, setState] = useState()
    const [selection, setSelection] = useState([])
    
    const textContent = useRef(null);
    const selectedText = useRef(null);

    const submitHandler = () => {
        props.getAnswer(selection)
    }
    
    const currentSelection = () =>{
        let text = textContent.current.innerHTML
        let userSelection = window.getSelection().toString()
        if(userSelection !== ""){
            let textTrimed = text.replace(/<[^>]*>?/gm,'')
            let newText = textTrimed.replace(userSelection, "<mark>"+userSelection+"</mark>")
            textContent.current.innerHTML = newText
            let userSelectionTrimed = userSelection.trim()
            setSelection([...selection, userSelectionTrimed])
        }
    }

    const clearSelection = () =>{
        setSelection([])
    }

    useEffect( () =>{
        setState(props.content)
    },[] )

    return (
        <div className="highlightText-container">
            <div className="text" ref={textContent} onMouseUp={currentSelection}>{state}</div>
            <div className="selection" ref={selectedText}>
                { selection.map( (el) => <span key={uniqid()}>{el}</span> ) }
                { 
                    selection.length ?  
                    <button className="btn btn-validate" onClick={clearSelection}>Supprimer ma sélection</button> :
                    null
                }
                
            </div>
            <button className="btn btn-validate" onClick={submitHandler}>Valider</button>
        </div>
    )
}

export default HighlightText