import { useState, useEffect } from 'react';

export default () => {

  const [error404, set404] = useState(false);
  let root = '/';
  let subject = '';
  let grade = '';
  let modul = '';
  let path = '';
  let activityId = '';
  let page = '';

  try {
    [root, subject, grade, modul, path, activityId, page] = window.location.pathname.split('/');
    useEffect(() => {
      if (!modul) {
        set404(true);
      }
    }, [modul]);
  } catch (error) {
    console.log('IN USELOCATION', error);
    useEffect(() => {
      set404(true);
    }, [error]);
  }

  return { subject, grade, modul, path, activityId, page, error404 };
}