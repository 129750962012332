// import axios from 'axios';

import * as TOKENS from '../constants/tokens';

// hatier-token

const FrK11Auth = require('fr-k11-auth');
// Overriding SDK API host
const ApiClient = new FrK11Auth.ApiClient();
ApiClient.basePath = process.env.REACT_APP_AUTH1_URL;
console.log(ApiClient.basePath)

const api = new FrK11Auth.DefaultApi(ApiClient);

function useAuth() {

    const getIdUserFromUrl = () => {
        let url = window.location.search.split('token_itineraire=');
        return url[url.length - 1];
    }

    const getAppToken = () => {
        return localStorage.getItem('hatier-token');
    }

    const getStrapiToken = () => {
        return localStorage.getItem('strapi-token');
    }

    const getUserType = () => {
        return localStorage.getItem('hatier-userType');
    }

    const serverAuth = async () => {
        try {
            // If token param in URL
            if (getIdUserFromUrl() !== "") {
                let opts = {
                    'cacheControl': "",
                    'xEvidencebApp': "itineraire.evidenceb.com"
                };
                // Send token to retrieve user info
                const res = await api.exchangeTokenUserTokenTokenPost(getIdUserFromUrl(), opts)
                if (res) {
                    console.log(res.hatier)
                    if (res.hatier.GUID) {
                        localStorage.setItem("hatier-token", res.hatier.GUID);
                    }
                    if (res.hatier.userType) {
                        localStorage.setItem("hatier-userType", res.hatier.userType);
                        if (res.hatier.userType === "teacher") {
                            let teacher = {
                                nom: res.hatier.last_name,
                                prenom: res.hatier.first_name,
                                classes: res.hatier.classes
                            };
                            console.log(teacher)
                            localStorage.setItem('teacherData', JSON.stringify(teacher));
                        }
                    }
                    return false;
                } else {
                    return true;
                }
            // If no token specified then get into demo token mode - free trial
            } else {
                if (getUserType() === "student" || getUserType() === "teacher" && getAppToken()) { // if previous user was a student, he can stay co
                    return false;
                } else {
                    if (window.location.pathname.includes('module1')) {
                        console.log('DEMO TOKEN');
                        localStorage.clear();
                        localStorage.setItem("hatier-token", TOKENS.TOKEN_DEMO);
                        localStorage.setItem("hatier-userType", TOKENS.TOKEN_DEMO);
                        return false;
                    } else {
                        throw new Error("Erreur d'authentification au serveur");
                    }
                }
            }
        }   catch (error) {
            console.log("Erreur d'authentification au serveur", error);
            throw new Error(error);
        }
    }

    const strapiAuth = async () => {
        if ( getAppToken() && !getStrapiToken() ) {
            try {
                let opts = {
                    'cacheControl': "" 
                };
                const GUID = localStorage.getItem("hatier-token")
                const res = await api.exchangeGuidUserGUIDGuidGet(GUID, opts)
                localStorage.setItem("strapi-token", res.evb.jwt);
                if (res.evb) {
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                console.log("Erreur d'authentification au site de contenu", error);
                throw new Error(error);
            }
        }
    }

    return {
        strapiAuth,
        serverAuth
    }
}
export default useAuth;