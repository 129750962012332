import {
  KEY_FR
} from '../constants/contentMap';

import * as ROUTES from '../constants/routes';
import {
  parseQCMChoices,
  parseQCMAnswers,
  parseDragChoices
} from '../utils/ia';
import {
  stringCleaner
} from '../utils/stringOperations';

import {isEqual} from 'lodash'
import { createPath } from 'history';

export const lastIndexesReducer = (state, action) => {
  // console.log("lastIndexesReducer");
  // console.log("Action", action.type);
  switch (action.type) {
    case 'SET':
      const { pathId, activityId } = action.payload;
      // console.log('new state', {
      //   ...state,
      //   pathId,
      //   activityId
      // });
      return {
        ...state,
        pathId,
        activityId
      }
    case 'RESET':
      return null
    default:
      return state;
  }
}

export const loadingReducer = (state, action) => {
  console.log("reducer LOADING");
  console.log("Action", action.type);
  const loadingBoolean = action.payload;
  switch (action.type) {
    case 'AUTH':
      return {
        ...state,
        auth: loadingBoolean,
      }
    case 'STRAPI':
      return {
        ...state,
        strapi: loadingBoolean
      }
    case 'TEACHER_DATA': {
      return {
        ...state,
        teacherData: loadingBoolean
      }
    }
    case 'HISTORY':
      return {
        ...state,
        history: loadingBoolean,
      }
    case 'TRACKING':
      return {
        ...state,
        tracking: loadingBoolean,
      }
    case 'RESET':
      return {
        auth: true,
        strapi: true,
        history: true,
        tracking: true
      }
    default:
      return state;
  }
}

export const demoReducer = (state, action) => {
  console.log("reducer DEMO");
  console.log("Action", action.type);

  switch (action.type) {
    case 'SET_DEMO':
      return {
        ...state,
        demo: true,
      }
    }
}

export const errorReducer = (state, action) => {
  console.log("reducer ERROR");
  console.log("Action", action.type);
  const error = action.payload;

  switch (action.type) {
    case 'AUTH':
      return {
        ...state,
        auth: error,
      }
    case 'STRAPI':
      return {
        ...state,
        strapi: error
      }
    case 'TEACHER_DATA': {
      return {
        ...state,
        teacherData: error
      }
    }
    // case 'HISTORY': 
    //   return {
    //     ...state,
    //     history: error
    //   } 
    // case 'TRACKING': 
    //   return {
    //     ...state,
    //     tracking: error
    //   }    
    case '404':
      return {
        ...state,
        e404: error
      }
    default:
      return state;
  }
}

export const indexesReducer = (state, action) => {
  console.log("reducer INDEXES");
  console.log("Action", action.type);
  const { pathId, sequence, answers, postHistory, postTracking, errors } = action.payload;

  switch (action.type) {
    case 'NEXT_CONTENT':
      // console.log({
      //   ...state,
      //   contentId: state.contentId + 1,
      //   action: action.type
      // })
      return {
        ...state,
        contentId: state.contentId + 1,
        action: action.type
      }
    case 'NEXT_EXERCICE':
      return {
        ...state,
        contentId: 0,
        exerciceId: state.exerciceId + 1,
        action: action.type
      }
    case 'NEXT_ACTIVITY':
      // If "test diagnostique" : record path chosen by IA in case student quits the app before beginning first activity
      if (state.pathId === 0 && state.activityId === 0) {
        sequence[state.pathId][state.activityId].push({"next_path": action.payload.pathId});
      }
      postHistory(
        state.localeId,
        state.subjectId,
        state.gradeId,
        state.moduleId,
        state.pathId,
        state.activityId,
        sequence[state.pathId][state.activityId])
        .then(({ data }) => {
          console.log('postHistory', JSON.stringify(data));
          postTracking(
            state.localeId,
            state.subjectId,
            state.gradeId,
            state.moduleId,
            state.pathId,
            state.activityId,
            answers[state.pathId][state.activityId])
            .then(({ data }) => {
              console.log('postTracking', JSON.stringify(data));
              window.location.replace(`/${KEY_FR[state.subjectId]}/${KEY_FR[state.gradeId]}/module${state.moduleId}/${(state.pathId === 0) ? pathId : state.pathId}/${state.activityId + 1}/${ROUTES.CHATBOT}`);
            })
            .catch(console.log);
        })
        .catch(error => console.log(error));
      return {
        ...state,
        contentId: 0,
        exerciceId: 1,
        activityId: state.activityId + 1,
        pathId: (state.pathId === 0) ? pathId : state.pathId,
        action: action.type
      }
    case 'NEXT_PATH':
      postHistory(
        state.localeId,
        state.subjectId,
        state.gradeId,
        state.moduleId,
        state.pathId,
        state.activityId,
        sequence[state.pathId][state.activityId])
        .then(({ data }) => {
          console.log('postHistory', JSON.stringify(data));
          postTracking(
            state.localeId,
            state.subjectId,
            state.gradeId,
            state.moduleId,
            state.pathId,
            state.activityId,
            answers[state.pathId][state.activityId])
            .then(({ data }) => {
              console.log('postTracking', JSON.stringify(data));
              window.location.replace(`/${KEY_FR[state.subjectId]}/${KEY_FR[state.gradeId]}/module${state.moduleId}/${state.pathId + 1}/1/${ROUTES.CHATBOT}`);
            })
            .catch(console.log);
        })
        .catch(error => console.log(error));
      return {
        ...state,
        contentId: 0,
        exerciceId: 1,
        activityId: 1,
        pathId: state.pathId + 1,
        action: action.type
      }
    case 'NEXT_MODULE':
      postHistory(
        state.localeId,
        state.subjectId,
        state.gradeId,
        state.moduleId,
        state.pathId,
        state.activityId,
        sequence[state.pathId][state.activityId])
        .then(({ data }) => {
          console.log('postHistory', JSON.stringify(data));
          postTracking(
            state.localeId,
            state.subjectId,
            state.gradeId,
            state.moduleId,
            state.pathId,
            state.activityId,
            answers[state.pathId][state.activityId])
            .then(({ data }) => {
              console.log('postTracking', JSON.stringify(data));
              window.location.replace(`/${KEY_FR[state.subjectId]}/${KEY_FR[state.gradeId]}/module${state.moduleId + 1}`);
            })
            .catch(console.log);
        })
        .catch(error => console.log(error));
      return {
        ...state,
        contentId: 0,
        exerciceId: 0,
        activityId: 0,
        pathId: 0,
        action: action.type
      }
    case 'REDO_ACTIVITY':
      const maxErrorsNumber = Object.values(errors).reduce((acc, current) => {
        acc = Math.max(acc, current);
        return acc;
      }, 0);
      const nextActivityId = Object.keys(errors).filter(key => errors[key] === maxErrorsNumber)[0];
      postHistory(
        'fr_FR',
        state.subjectId,
        state.gradeId,
        state.moduleId,
        state.pathId,
        state.activityId,
        sequence[state.pathId][state.activityId])
        .then(({ data }) => {
          console.log('postHistory', JSON.stringify(data));
          postTracking(
            'fr_FR',
            state.subjectId,
            state.gradeId,
            state.moduleId,
            state.pathId,
            state.activityId,
            answers[state.pathId][state.activityId])
            .then(({ data }) => {
              console.log('postTracking', JSON.stringify(data));
              window.location.replace(`/${KEY_FR[state.subjectId]}/${KEY_FR[state.gradeId]}/module${state.moduleId}/${state.pathId}/${nextActivityId}/${ROUTES.CHATBOT}`);
            })
            .catch(console.log);
        })
        .catch(error => console.log(error));
      return {
        ...state,
        contentId: 0,
        exerciceId: 1,
        activityId: nextActivityId,
        action: action.type
      }
    case 'LAUNCH_ACTIVITY':
      return {
        ...state,
        exerciceId: 1,
        contentId: 0,
        action: action.type
      }
    case 'FINAL_TEST':
      postHistory(
        state.localeId,
        state.subjectId,
        state.gradeId,
        state.moduleId,
        state.pathId,
        state.activityId,
        sequence[state.pathId][state.activityId])
        .then(({ data }) => {
          console.log('postHistory', JSON.stringify(data));
          postTracking(
            state.localeId,
            state.subjectId,
            state.gradeId,
            state.moduleId,
            state.pathId,
            state.activityId,
            answers[state.pathId][state.activityId])
            .then(({ data }) => {
              console.log('postTracking', JSON.stringify(data));
              window.location.replace(`/${KEY_FR[state.subjectId]}/${KEY_FR[state.gradeId]}/module${state.moduleId}/${state.pathId}/${state.activityId + 1}/${ROUTES.CHATBOT}`);
            })
            .catch(console.log);
        })
        .catch(error => console.log(error));
      return {
        ...state,
        activityId: state.activityId + 1,
        exerciceId: 1,
        contentId: 0,
        action: action.type
      }
    case 'FINISH_PATH':
      postHistory(
        state.localeId,
        state.subjectId,
        state.gradeId,
        state.moduleId,
        state.pathId,
        state.activityId,
        sequence[state.pathId][state.activityId])
        .then(({ data }) => {
          console.log('postHistory', JSON.stringify(data));
          postTracking(
            state.localeId,
            state.subjectId,
            state.gradeId,
            state.moduleId,
            state.pathId,
            state.activityId,
            answers[state.pathId][state.activityId])
            .then(({ data }) => {
              console.log('postTracking', JSON.stringify(data));
              window.location.replace(`/${KEY_FR[state.subjectId]}/${KEY_FR[state.gradeId]}/module${state.moduleId}/${state.pathId}/${state.activityId}/${ROUTES.PATH}`);
            })
            .catch(console.log);
        })
        .catch(error => console.log(error));
      return {
        ...state
      }
    case 'SET_INDEXES':
      return {
        ...action.payload
      }
    default:
      return {
        ...state,
        action: action.type
      }
  }
};

export const sequenceReducer = (state, action) => {
  console.log("reducer SEQUENCE");
  console.log("Action", action.type);
  const { pathId, activityId, lastItem, answer } = action.payload;

  if (
    pathId &&
    !state[pathId]
  ) {
    state[pathId] = [];
  }

  // console.log(pathId, activityId);
  switch (action.type) {
    case 'ADD_MESSAGE':
      if (
        pathId !== undefined &&
        activityId !== undefined
      ) {
        const lastItemClean = (lastItem) ?
          lastItem : state[pathId] && state[pathId][activityId] ?
            state[pathId][activityId].slice(-1)[0] : {};
        if (state[pathId][activityId] && lastItemClean && lastItemClean.length !== undefined) { // when we add several contents
          state[pathId][activityId] = [...state[pathId][activityId], ...lastItemClean];
        } else if (state[pathId][activityId] && lastItemClean && lastItemClean.length === undefined) { // in this case we passed an object
          state[pathId][activityId].push({ ...lastItemClean });
        } else {
          state[pathId][activityId] = [lastItemClean];
        }
      }
      // console.log([...state]);
      return [...state]
    case 'CLICK':
      if (pathId !== undefined && activityId !== undefined) {
        const lastItemClean = (lastItem) ?
          lastItem : state[pathId][activityId].slice(-1)[0];
        lastItemClean.type = 'answer';
      }
      return [...state]
    case 'CHOICE':
      // console.log('lastItem before', lastItem);
      const newItem = {
        ...lastItem,
        type: 'answer',
        content: answer
      };
      // console.log('lastItem after', newItem);
      state[pathId][activityId].push(newItem);
      return [...state]
    case 'ANSWER':
      if (pathId !== undefined && activityId !== undefined) {
        const lastItemClean = (lastItem) ?
          lastItem : state[pathId][activityId].slice(-1)[0];
        lastItemClean.type = 'answer';
        lastItemClean.content = Array.isArray(action.payload.answer) ? action.payload.answer.join(' ') : action.payload.answer;
      }
      return [...state]
    case 'POPULATE_SEQUENCE':
      // console.log(action.payload);
      const { sequence } = action.payload;
      if (
        pathId !== undefined &&
        activityId !== undefined &&
        state[pathId]
      ) {
        // console.log('old state', [...state]);
        state[pathId][activityId] = sequence;
        // console.log('new state', [...state]);
        return [...state]
      } else if (
        pathId !== undefined &&
        activityId !== undefined &&
        !state[pathId]
      ) {
        state[pathId][activityId] = [];
      }
    case 'RESET_SEQUENCE_ACTIVITY':
      state[pathId].splice(activityId - 1, 1);
      return [...state]
    case 'DELETE_ITEM':
      const { itemId } = action.payload;
      state[pathId][activityId].splice(itemId, 1);
      return [...state]
    case 'RESET_SEQUENCE':
      return [[[]]]
    default:
      return [...state];
  }
};

export const answersReducer = (state, action) => {
  console.log("reducer ANSWERS");
  console.log("Action", action.type);
  const { pathId, activityId, lastItem, answer, index } = action.payload;
  const trackAnswer = {};
  trackAnswer.tms = Date.now();
  trackAnswer.type = action.type;
  trackAnswer.activityId = lastItem && lastItem.activity || 0;
  trackAnswer.exerciceId = lastItem && lastItem.exercice || 0;
  trackAnswer.pathId = lastItem && lastItem.path || 0;
  if (
    pathId &&
    !state[pathId]
  ) {
    state[pathId] = [];
  }
  // console.log(pathId, activityId, lastItem, answer, index );

  switch (action.type) {
    case 'CLICK':
      if (pathId !== undefined && activityId !== undefined) {
        if (state[pathId][activityId]) {
          state[pathId][activityId].push(trackAnswer);
        } else {
          state[pathId][activityId] = [trackAnswer];
        }
      };
      return [...state]
    case 'QCMET':
     
      if (pathId !== undefined && activityId !== undefined && lastItem && index !== undefined && index !== null) {
        
        const correctAnswers = lastItem.correct_answer.split(',').sort().map(Number);
        const indexes = index.sort()
        trackAnswer.isCorrect = isEqual(correctAnswers, indexes);
        
        if (state[pathId][activityId]) {
          state[pathId][activityId].push(trackAnswer);
        } else {
          state[pathId][activityId] = [trackAnswer];
        }

      }
      return [...state]
    case 'QCMOU':
      if (pathId !== undefined && activityId !== undefined && lastItem && index !== undefined && index !== null) {
        
        const correctAnswers = lastItem.correct_answer.split(',').sort().map(Number);
        const indexes = index.sort()
        trackAnswer.isCorrect = false;

        for(let i=0; indexes.length > i; i++){
          let isCorrect = correctAnswers.find( correctAnswer => indexes[i] === correctAnswer )
          if( isCorrect ){
            trackAnswer.isCorrect = true;
          }else{
            trackAnswer.isCorrect = false;
            break;
          }
        }
        
        if (state[pathId][activityId]) {
          state[pathId][activityId].push(trackAnswer);
        } else {
          state[pathId][activityId] = [trackAnswer];
        }

      }
      return [...state]
    case 'QCU':
      if (pathId !== undefined && activityId !== undefined && lastItem && index !== undefined && index !== null) {
        
        let numberPattern = /\d+/g;
        const correctAnswer = lastItem.correct_answer.match(numberPattern).map(Number);
       
        let isCorrect;
        for(let i = 0; correctAnswer.length > i; i++){
          // console.log('DEBUG - correctAnswer[i]', correctAnswer[i]);
          // console.log('DEBUG - parseInt(index)', parseInt(index));

          if( correctAnswer[i] === parseInt(index) + 1 ){
            isCorrect = true
            break;
          }
        }
        // console.log('DEBUG - index', index);

        // console.log('DEBUG - lastItem.correct_answer', lastItem.correct_answer)
        // console.log('DEBUG - correctAnswer', correctAnswer)
        trackAnswer.isCorrect = isCorrect ? true : false
        // console.log('DEBUG - isCorrect', isCorrect)
        // console.log('DEBUG - trackAnswer', trackAnswer)
        if (state[pathId][activityId]) {
          state[pathId][activityId].push(trackAnswer);
        } else {
          state[pathId][activityId] = [trackAnswer];
        }

      }
      return [...state];
    case 'DRAG':
      if (pathId !== undefined && activityId !== undefined && lastItem && answer) {
        const rawCorrectAnswer = lastItem.correct_answer.replace('.', '').trim();
        const cleanCorrectAnswer = Array.from(rawCorrectAnswer
          .substr(1, rawCorrectAnswer.length - 2)
          .split("||"))
          .map(item => item.trim().toLowerCase());
        let isAMatch = true;
        const answerClean = answer.map(item => item.toLowerCase());
        for (let i = 0; i < answerClean.length; i++) {
          if (answerClean[i] !== cleanCorrectAnswer[i]) {
            isAMatch = false;
            break;
          }
        }
        trackAnswer.isCorrect = isAMatch;
        if (state[pathId][activityId]) {
          state[pathId][activityId].push(trackAnswer);
        } else {
          state[pathId][activityId] = [trackAnswer];
        }
      }
      return [...state];
    case 'MISSING':
      if (pathId !== undefined && activityId !== undefined && lastItem) {

        const cleanA = answer.map(ans => stringCleaner(ans));
        // console.log('DEBUG - cleanA', cleanA)
        const rawCorrectAnswer = lastItem.correct_answer;
        const cleanCorrectAnswer = Array.from(rawCorrectAnswer
          .substr(1, rawCorrectAnswer.length - 2)
          .split("||"))
          .map(clean => stringCleaner(clean));
        // console.log('DEBUG - cleanCorrectAnswer', cleanCorrectAnswer)
        
        trackAnswer.isCorrect = (cleanA.length === cleanCorrectAnswer.length) ?
          [...Array(cleanCorrectAnswer.length).keys()].every(i => cleanCorrectAnswer[i] === cleanA[i]) :
          (cleanA.length === 1 && cleanCorrectAnswer.length > 1) ?
            [...Array(cleanCorrectAnswer.length).keys()].some(i => cleanCorrectAnswer[i] === cleanA[0]) :
            false; // this case should not happen, if so, pb in cms
        
        // console.log('DEBUG 1', cleanA.length);
        // console.log('DEBUG 2', cleanCorrectAnswer.length);
        // console.log('DEBUG 3', [...Array(cleanCorrectAnswer.length).keys()]);
        // console.log('DEBUG 4', [...Array(cleanCorrectAnswer.length).keys()].every(i => cleanCorrectAnswer[i] === cleanA[i]));
        [...Array(cleanCorrectAnswer.length).keys()].every(i => {
          // console.log('DEBUG 5', i)
          // console.log('DEBUG 6', cleanCorrectAnswer[i])
          // console.log('DEBUG 7', cleanA[i])
          return cleanCorrectAnswer[i] === cleanA[i]
        });

        if (state[pathId][activityId]) {
          state[pathId][activityId].push(trackAnswer);
        } else {
          state[pathId][activityId] = [trackAnswer];
        }
      }
      return [...state];
    case 'HIGHLIGHT':
      if (pathId !== undefined && activityId !== undefined && lastItem) {
        if (answer.length === 0) {
          const rawCorrectAnswer = lastItem.correct_answer;
          const cleanCorrectAnswer = rawCorrectAnswer.trim();
          trackAnswer.isCorrect = cleanCorrectAnswer === '';
        } else if (answer.length === 1) {
          const cleanA = answer[0].replace(/[.,;:!?'\[\]]/g, "").trim();
          const rawCorrectAnswer = lastItem.correct_answer;
          const cleanCorrectAnswer = rawCorrectAnswer.replace(/[.,;:!?'\[\]]/g, "").trim();
          trackAnswer.isCorrect = cleanCorrectAnswer === cleanA;
        } else {
          const cleanA = answer.map(i => i.replace(/[.,;:!?'\[\]]/g, "").trim()).sort();
          const rawCorrectAnswer = lastItem.correct_answer;
          let cleanCorrectAnswer = rawCorrectAnswer.replace(/[.,;:!?'\[\]]/g, "").split('||').sort();
          trackAnswer.isCorrect = cleanA.map(i => i.trim()).every((item, index) => cleanCorrectAnswer[index] === item);
        };
        if (state[pathId][activityId]) {
          state[pathId][activityId].push(trackAnswer);
        } else {
          state[pathId][activityId] = [trackAnswer];
        }
      }
      return [...state]
    case 'CHOICE':
      trackAnswer.content = answer;
      state[pathId][activityId].push(trackAnswer);
      return [...state];
    case 'POPULATE_ANSWERS':
      // console.log(action.payload);
      const { answers } = action.payload;
      if (
        pathId !== undefined &&
        activityId !== undefined &&
        state[pathId]
      ) {
        console.log('old state', [...state]);
        state[pathId][activityId] = answers;
        console.log('new state', [...state]);
        return [...state]
      } else if (
        pathId !== undefined &&
        activityId !== undefined &&
        !state[pathId]
      ) {
        state[pathId] = [];
        console.log('old state', [...state]);
        state[pathId][activityId] = answers;
        console.log('new state', [...state]);
        return [...state]
      }

    default:
      return state;
  }
};

export const iaReducer = (state, action) => {
  console.log("reducer IA");
  console.log("Action", action.type);
  const { activity, pathId, lastItem, finalTest } = action.payload;
  // console.log('action.payload', action.payload);
  switch (action.type) {
    case 'SHUFFLE_ACTIVITY':
      if (activity && activity.length > 0) {
        return {
          ...state,
          activity
        }
      } else {
        return { ...state }
      }
    case 'ALLOCATE_PATH':
      return {
        ...state,
        pathId
      }
    case 'FINAL_TEST':
      return {
        ...state,
        activity
      }
    default:
      return {
        ...state,
        action: action.type
      }
  }
}

export const historyReducer = (state, action) => {
  console.log("reducer HISTORY");
  console.log("Action", action.type);
  const { pathId, activityId } = action.payload;
  switch (action.type) {
    case 'ACTIVITIES':
      if (state.activitiesState[pathId]) {
        state.activitiesState[pathId][activityId] = true;
      } else {
        state.activitiesState[pathId] = {};
        state.activitiesState[pathId][activityId] = true;
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

function rFact(num) {
  if (num === 0) { return 1; }
  else { return num * rFact(num - 1); }
}

function calculateCombinations(k, n) {
  return rFact(n) / (rFact(k) * rFact(n - k))
}

const calculateQCMETRandomSuccess = (k, n) => {
  if ( (n-1 !== k) && (k !== n) ) {
      let result = 0;
      for (let i = k; i <= (n-1); i++) {
      let combination = calculateCombinations(i, n);
      result += combination;
      } 
      return result;
  } else {
      return calculateCombinations(k, n);
  }
}

export const statisticsReducer = (state, action) => {
  console.log("reducer STATISTICS");
  console.log("Action", action.type);
  const { moduleAnswers } = action.payload;
  switch (action.type) {
    case 'NUMBER_UNIQUE_EXERCICES':
      let numberUniqueExercices = moduleAnswers.reduce((acc, current, index, array) => {
        if (
          index === 0 &&
          current
        ) {
          acc = 1;
        } else if (
          index > 0 &&
          current &&
          array[index - 1] &&
          current.exerciceId &&
          !current.content &&
          (
            current.activityId !== array[index - 1].activityId ||
            current.activityId === array[index - 1].activityId &&
            current.exerciceId !== array[index - 1].exerciceId
          )
        ) {
          acc += 1
        }
        return acc;
      }, 0);
      return {
        ...state,
        numberUniqueExercices
      }
    case 'MEAN_TIME':
      const base = moduleAnswers
        .filter(answer =>
          answer &&
          answer.exerciceId &&
          !answer.content
        );
      const totalTime = base.reduce((acc, current, index, array) => {
        if (
          index > 0 &&
          (current.tms - array[index - 1].tms) / 1000 < 30 &&
          (current.tms - array[index - 1].tms) / 1000 > 0
        ) {
          const diff = (current.tms - array[index - 1].tms) / 1000;
          // console.log(diff)
          acc[0] += diff;
          acc[1] += 1;
        }
        return acc;
      }, [0, 0]);
      const meanTime = parseFloat((totalTime[0] / totalTime[1]).toPrecision(3));
      return {
        ...state,
        meanTime
      }
    case 'TRAINING_TIME':
      const sortedBase = moduleAnswers
        .filter(answer =>
          answer &&
          answer.exerciceId &&
          !answer.content
        );

      const timeMs = sortedBase.reduce((total, current, index, array) => {
        if (
            index > 0 &&
            (current.tms - array[index - 1].tms) / 1000 < 300 &&
            (current.tms - array[index - 1].tms) / 1000 > 0
          ){
            const diff = (current.tms - array[index - 1].tms)
            total += diff;
        }
        return total;
      }, 0)

      const trainingTime = Math.round(parseFloat(timeMs) / 60000)

      return {
        ...state,
        trainingTime
      }
    case 'CORRECT_RATE':
      const numberUniqueSuccesses = moduleAnswers.reduce((acc, current, index, array) => {
        if (
          index === 0 &&
          current &&
          current.isCorrect
        ) {
          acc = 1;
        } else if (
          index > 0 &&
          current &&
          array[index - 1] &&
          current.exerciceId &&
          !current.content &&
          current.isCorrect &&
          (
            current.activityId !== array[index - 1].activityId ||
            current.activityId === array[index - 1].activityId &&
            current.exerciceId !== array[index - 1].exerciceId
          )
        ) {
          acc += 1
        }
        return acc;
      }, 0);
      const numberUnique = moduleAnswers.reduce((acc, current, index, array) => {
        if (
          index === 0 &&
          current
        ) {
          acc = 1;
        } else if (
          index > 0 &&
          current &&
          array[index - 1] &&
          current.exerciceId &&
          !current.content &&
          (
            current.activityId !== array[index - 1].activityId ||
            current.activityId === array[index - 1].activityId &&
            current.exerciceId !== array[index - 1].exerciceId
          )
        ) {
          acc += 1
        }
        return acc;
      }, 0);

      const result = parseFloat((numberUniqueSuccesses / numberUnique).toPrecision(3));
      // console.log(result);
      return {
        ...state,
        correctRate: result
      }
    case 'CORRECT_NUMBER':
      const numberSuccesses = moduleAnswers.reduce((acc, current, index, array) => {
        if (
          index === 0 &&
          current &&
          current.isCorrect
        ) {
          acc = 1;
        } else if (
          index > 0 &&
          current &&
          array[index - 1] &&
          current.exerciceId &&
          !current.content &&
          current.isCorrect &&
          (
            current.activityId !== array[index - 1].activityId ||
            current.activityId === array[index - 1].activityId &&
            current.exerciceId !== array[index - 1].exerciceId
          )
        ) {
          acc += 1
        }
        return acc;
      }, 0);
      return {
        ...state,
        correctNumber: numberSuccesses
      }
    case 'CONSECUTIVE_SUCCESSES':
      const consecutiveSuccesses = moduleAnswers.reduce((acc, current, index, array) => {
        if (
          (
            index === 0 &&
            current &&
            current.isCorrect &&
            current.exerciceId
          ) ||
          (
            index > 0 &&
            current &&
            current.isCorrect &&
            current.exerciceId &&
            array[index - 1] &&
            array[index - 1].isCorrect &&
            array[index - 1].exerciceId
          )
        ) {
          // console.log('IS CORRECT CUM');
          // console.log('current', current);
          acc[0] += 1;
          acc[1] = Math.max(acc[0], acc[1]);
          // console.log('acc', acc);
        }
        return acc;
      }, [0, 0]);
      // console.log(consecutiveSuccesses);
      return {
        ...state,
        consecutiveSuccesses: consecutiveSuccesses[1]
      }
    case 'SUCCESS_IF_RANDOM':
      const { exercices, indexes } = action.payload;
      console.log('moduleAnswers', JSON.stringify(moduleAnswers));
  
      const eligibleAnswers = moduleAnswers.filter((current, index, array) => {
        return (
          current &&
          current.exerciceId &&
          (
            current.type === 'QCU' ||
            current.type === 'QCMET' ||
            current.type === 'QCMOU' ||
            current.type === 'DRAG'
          ) &&
          (
            (index === 0) ||
            (
              index > 0 &&
              array[index - 1]
            ) &&
            (
              current.pathId !== array[index - 1].pathId ||
              current.pathId === array[index - 1].pathId &&
              current.activityId !== array[index - 1].activityId ||
              current.pathId === array[index - 1].pathId &&
              current.activityId === array[index - 1].activityId &&
              current.exerciceId !== array[index - 1].exerciceId
            )
          )
        )
      });
      console.log('eligibleAnswers', eligibleAnswers);
      // debugger;
      const moduleExercices = exercices.filter(item =>
        item.module === indexes.moduleId &&
        item.type !== 'message' &&
        eligibleAnswers.filter(answer =>
          answer.pathId === item.path &&
          answer.activityId === item.activity &&
          answer.exerciceId === item.exercice
        ).length > 0
      );
      console.log('moduleExercices', moduleExercices);
  
  
      const probabiliteCorrectAnswer = moduleExercices.map(item => {
        if (item.type === 'DRAG') {
          const l = parseDragChoices(item.content).length;
          return 1 / rFact(l)
        } else if (item.type === 'QCMET') {
          const nbCorrectAnswers = parseQCMAnswers(item.correct_answer).length
          const nbChoices = parseQCMChoices(item.content).length
          return 1 / calculateQCMETRandomSuccess(nbCorrectAnswers, nbChoices);
        } else if ((item.type === 'QCMOU') || (item.type === 'qcm')) {
          const nbChoices = parseQCMChoices(item.content).length;
          return 1 / nbChoices;
        }
      });

      console.log('probabiliteCorrectAnswer', probabiliteCorrectAnswer);

      const correctRate = probabiliteCorrectAnswer.reduce((acc, current) => {
        acc[0] += current;
        acc[1] += 1;
        return acc
      }, [0, 0])
      console.log('correctRate', correctRate);
      return {
        ...state,
        ifRandom: parseFloat((correctRate[0]).toPrecision(3))
      }
    default:
      return state
  }
}