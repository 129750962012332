import React from 'react';
import uniqid from 'uniqid';

export const nameCapitalized = (name) => name.charAt(0).toUpperCase() + name.slice(1)

export const toItalic = (str) => str.split('*').map((frag, index) => {
  if (index % 2 !== 0) {
    return <em key={uniqid()}>{frag}</em>
  } else {
    return frag
  }
});

export const toBold = (str) =>  str.split('**').map((frag, index) => {
  if (index % 2 !== 0) {
    return <strong key={uniqid()}>{frag}</strong>
  } else {
    return frag
  }
});

export const toNewline = (str) => str.split('\\n').map((frag, index) => {
  if (index % 2 !== 0) {
    return <p key={uniqid()}>{frag}</p>
  } else {
    return frag
  }
});

export const stringCleaner = (s) => {
  const regexpPonctuate = /(\.|,|'|")/g;
  const regexpAccent = /[\u0300-\u036f]/g;
  const regexpSpace = /(\xC2\xA0|&nbsp;|\s+)/g;
  const regexpEmotiocones = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
  return s
  .toLowerCase()
  .normalize("NFD").replace(regexpAccent, '')
  .replace(regexpPonctuate, '')
  .replace(regexpSpace, ' ')
  .replace(regexpEmotiocones, '')
  .trim()
}
