import React from 'react';
import { Spin } from 'antd';


const Loading = () => {
  return (
    <div className="centered-container">
      <div>
          <Spin tip="Loading..."/>
      </div>
    </div>
  );
};

export default Loading;
