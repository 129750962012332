import React from 'react'
import logoHatier from '../assets/images/hatier_logo.gif';
import '../scss/Footer.scss'

function Footer() {
  return (
    <footer id="footer">
      <div className="col">
        <span>
          <span className="bold">Itinéraires littéraires</span>
          Français 1re
        </span>
        <a href="https://www.editions-hatier.fr/">
          <img src={logoHatier} />
        </a>
      </div>
      <div className="col">
        <span>
            <span className="bold">eContact</span>
            <a href="mailto:contact@evidenceB.com" target="_blank">contact@evidenceB.com</a>
        </span>
      </div>
    </footer>
  )
}

export default Footer
