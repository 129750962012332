import { arrIsEmpty, objIsEmpty } from './arrayOperations';

export const needsUserInput = (currentContentType) => !(
  currentContentType === 'message' ||
  currentContentType === 'feedback' ||
  currentContentType === 'answer'
);

export const endExercice = (currentContentId, maxContentId) => (currentContentId === maxContentId);
export const endActivity = (currentExoId, maxExoId) => (currentExoId === maxExoId);
export const endPath = (currentActivityId, maxActivityId) => (currentActivityId === maxActivityId);
export const endModule = (currentModuleId, maxModuleId) => (currentModuleId === maxModuleId);