import React, { useContext, useEffect } from 'react';
import { Drawer } from 'antd';
import { withRouter } from 'react-router-dom';
import Activity from '../containers/Activity';
import { StoreContext } from '../containers/Store';
import GameLoop from '../containers/GameLoop';
import IA from '../containers/IA';

const ActivityDrawerTeacher = () => {
  let {
    data: {
      dispatchSequence,
      dispatchIA
    },
    drawer: {
      titleRightDrawer,
      visibleRightDrawer,
      setRightDrawer,
      reference,
      setStepId,
      setPathId,
    },
  } = useContext(StoreContext);

  const [inspirations, sources] = reference;

  return (
    <Drawer
      title={titleRightDrawer}
      placement="right"
      width={window.innerWidth < 520 ? window.innerWidth-50: Math.floor(window.innerWidth / 2)}
      closable={false}
      onClose={() => {
        setRightDrawer(false);
        dispatchSequence({ type: 'RESET_SEQUENCE', payload: {}});
        setPathId();
        setStepId();
      }}
      visible={visibleRightDrawer}
    >
      {/* Teacher logic */}
      {
        <>
          {(reference[0] || reference[1]) && 
            <div>
              <h4> Références </h4>
              <p> {inspirations} </p>
              <p> <em> {sources} </em></p>
            </div>
          }
          <IA>
            <GameLoop>
              <Activity />
            </GameLoop>
          </IA>
        </>
      }
    </Drawer>)
};

export default withRouter(ActivityDrawerTeacher);
