import React, { useContext } from 'react';

import { StoreContext } from '../../containers/Store';
import PathTeacher from './PathTeacher';
import { Row, Col } from 'antd';
import { toItalic } from '../../utils/stringOperations';
import ActivityDrawerTeacher from '../../components/ActivityDrawerTeacher';

import "../../scss/Gallery.scss"

export const Gallery = () => {

  const {
    data: {
      indexes,
      dispatchIndexes,
      dispatchIA
    },
    cms: {
      modules,
      paths
    },
    drawer: {
      setRightDrawer,
      setRightDrawerTitle,
      setStepId,
      setPathId
    },
  } = useContext(StoreContext);

  const extractDifficulty = (pathId) => {
    if (!!paths) {
      if (paths.filter(p =>
        p.path === pathId).length > 0) {
        const path = paths.filter(p =>
          p.path === pathId)[0];
        return toItalic(path.difficulty);
      } else {
        return ""
      }
    } else {
      return ""
    }
  }

  const extractDescription = (pathId) => {
    if (!!paths) {
      if (paths.filter(p =>
        p.path === pathId).length > 0) {
        const path = paths.filter(p =>
          p.path === pathId)[0];
        return toItalic(path.description);
      } else {
        return ""
      }
    } else {
      return ""
    }
  }

  const references = modules[0].know_more && modules[0].know_more.split('||') || [];

  return (
    <div id="teacher-gallery">
      <div className="card">
        <h1>{"Module " + modules[0].module.toString()}</h1>
        <h1 className="title">
          {modules && modules.length && modules[0].description || ''}
        </h1>
        {modules && modules.length && modules[0].know_more &&
          <div className="content">
            <h4>Références</h4>
            {references.map(ref => <p> {toItalic(ref)} </p>)}
          </div>
        }
      </div>

      <button className="btn btn-validate"
        onClick={() => {
          setRightDrawerTitle('Test diagnostique');
          setRightDrawer(true);
          setPathId(0);
          setStepId(0);
          dispatchIA({ type: 'GAMELOOP', payload: {} });
          dispatchIndexes({
            type: 'SET_INDEXES',
            payload: {
              ...indexes,
              pathId: 0,
              activityId: 0,
              exerciceId: 0,
              contentId: 0,
            }
          })
        }}
      >Test diagnostique</button>

      <div className="row flex margin-0-5em">
        <div className="col">
          <div className="card">
            <h2 className="title">Premier parcours</h2>
            <div className="content">
              <h4><em>Objectif</em></h4>
              <p>{extractDescription(1)}</p>
              <h4> <em>Difficultés visées</em> </h4>
              <p>{extractDifficulty(1)}</p>
            </div>
          </div>
          <PathTeacher pathId={1} />
        </div>
        <div className="col">
          <div className="card">
            <h2 className="title">Deuxième parcours</h2>
            <div className="content">
              <h4><em>Objectif</em></h4>
              <p>{extractDescription(2)}</p>
              <h4> <em>Difficultés visées</em> </h4>
              <p>{extractDifficulty(2)}</p>
            </div>
          </div>
          <PathTeacher pathId={2} />
        </div>
        <div className="col">
          <div className="card">
            <h2 className="title">Troisième parcours</h2>
            <div className="content">
              <h4><em>Objectif</em></h4>
              <p>{extractDescription(3)}</p>
              <h4> <em>Difficultés visées</em> </h4>
              <p>{extractDifficulty(3)}</p>
            </div>
          </div>
          <PathTeacher pathId={3} />
        </div>
      </div>
      <ActivityDrawerTeacher />
    </div>
  )
}

export default Gallery;