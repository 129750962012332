import React, { useState, useEffect } from 'react';
import Sortable from 'react-sortablejs';
import uniqid from 'uniqid';
import '../scss/DragAndDrop.scss'

function DragAndDrop(props) {

    const [state, setState] = useState({items:[]})

    const submitHandler = () => {

        props.getAnswer(state.items)
    }

    useEffect( () => {
        if(props.content){
            setState({
                ...items,
                items : props.content
            })
        }
        console.log("choix",props.content);
        
    },[items])

    const items = state.items.map((val) => (<div key={uniqid()} data-id={val}>{val}</div>));

    return (
        <div className="sortable-container">
            <Sortable className="sortable"
                /* tag="ul" */ // Defaults to "div"
                options={{
                    swapThreshold: 1,
                    animation: 150,
                    ghostClass: 'selected'
                }}
                onChange={(order, sortable, evt) => {
                    setState({ items: order });
                }}
            >
                {items}
            </Sortable>
            <button className="btn btn-validate" onClick={submitHandler}>Confirmer</button>
        </div>
    );

}

export default DragAndDrop
