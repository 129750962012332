
import React, { useContext, useEffect, useState, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useParams, useRouteMatch, useHistory, withRouter  } from 'react-router-dom';

import { StoreContext } from '../../containers/Store';

import Student from './Student';
import useParseLocation from '../../hooks/useParseLocation';
import useApi from '../../hooks/useApi';

import StudentImage from '../../assets/images/student.png';
import '../../scss/Classroom.scss';

import { Select } from 'antd';

const { Option } = Select;


const Modules = ({ modules }) => {
  const { modul } = useParseLocation();

  const getModuleNb = (module) => "module" + module.module.toString();

  const handleChange = (value) => {
    let modulePath = window.location.pathname.replace(modul, value)
    window.location.replace(modulePath);
  };

  return (
    <Fragment>
      <h3> Modules - Sélectionnez un module </h3>
      {modules.length > 0 ?
        <Select 
          defaultValue={modul}
          style={{ width: "100%" }}
          onChange={handleChange}
        >
          {modules.map((module, i) => {
            return (
              <Option 
                key={i}
                value={getModuleNb(module)}
              >{"M" + module.module + " - " + module.name}</Option>
            )
          })}
        </Select>
        : "La sélection par module est indisponible."
      }

    </Fragment>
  )
}

const Students = ({ classroomId, students, url }) => {
  let history = useHistory();

  const [currentStudent, setCurrentStudent] = useState()

  useEffect(() => {
    const urlStudent = window.location.pathname.split(classroomId+"/")[1];
    urlStudent ? setCurrentStudent(urlStudent) : setCurrentStudent("- Sélectionnez un élève -");
  });

  const handleChange = (value) => {
    history.push(`${url}/${value}`);
    setCurrentStudent(value)
  };

  return (
    <Fragment>
      <h3> Classe {classroomId} - Sélectionnez un élève</h3>
      {(students && students.length) ? 
        <Select
          value={currentStudent}
          style={{ width: "100%" }}
          onChange={handleChange}
        >
          {students.map((student, i) => {
            console.log('IN CLASSROOM, student', student.first_name);
            return (
              <Option 
                key={i}
                value={student.GUID}
              >{student.first_name + " " + student.last_name}</Option>
            )
          })} 
        </Select>
      : <p> Aucun élève ne s'est connecté pour l'instant.</p>}
    </Fragment>
  )
}


const Classroom = ({ classrooms }) => {
  const { subject, grade } = useParseLocation();
  const { allModules } = useApi({
    subject,
    grade
  });

  const {
  } = useContext(StoreContext);

  useEffect(() => {
    console.log('CLASSROOM MOUNTED');
    return () => console.log('CLASSROOM UNMOUNTED');
  }, [])

  let { url, path } = useRouteMatch();

  let { classroomId } = useParams();

  const [students, setStudents] = useState([]);

  useEffect(() => {
    const stu = classrooms && classrooms.length && classrooms
      .filter(classroom => classroom.name === classroomId)
      .map(classroom => classroom.students)[0] || [];
    setStudents(stu);
  }, [url])

  return (
    <div className="classroom-container">
      <Students students={students} classroomId={classroomId} url={url} />
      <Route path={`${path}/:studentId`} render={() => 
        <Fragment>
          <Modules modules={allModules} />
          <Student />
        </Fragment>
      } />
    </div>
  )
};

export default Classroom;