import React from 'react'

import logoEvidenceB from '../assets/images/evidenceb_logo.png';
import logoHatier from '../assets/images/hatier_logo.gif';
import '../scss/Logo.scss';

function Logo() {
    
    return (
        <div id="logo">
             <a href="https://www.editions-hatier.fr/" target="_blank">
                <img className="logo hatier" src={logoHatier} alt='logo hatier' />
             </a>
             <a href="http://www.evidenceb.com/" target="_blank">
                <img className="logo evidenceb" src={logoEvidenceB} alt='logo evidenceb' />
            </a>
        </div>
    )
}

export default Logo
