import compareObj from "./compareObj";

export const objIsEmpty = (obj) => {
  return !obj || compareObj(obj, {});
}

export const arrIsEmpty = (arr) => {
  return !arr || arr && arr.length === 0;
}

export const getMaxContentIds = (exos, indices, finalTestId) => {
  return exos.reduce((acc, current, index, array) => {
    // console.log(current);
    const exercice = exos
      .filter(item => (
        item.grade === current.grade &&
        item.module === current.module &&
        item.path === current.path &&
        item.activity === current.activity &&
        item.newExerciceId === current.newExerciceId
      ))
      .map(item => item.newContentId);
    let key;

    if (indices.activityId === finalTestId) { // for final test
      key =  parseInt(current.newExerciceId) < 10 ?
      `${current.module}00${current.path}00${finalTestId}00${current.newExerciceId}` :
      `${current.module}00${current.path}00${finalTestId}0${current.newExerciceId}`;  
    } else {
      key =  parseInt(current.newExerciceId) < 10 ?
      `${current.module}00${current.path}00${current.activity}00${current.newExerciceId}` :
      `${current.module}00${current.path}00${current.activity}0${current.newExerciceId}`;  
    }
    if (
      index === 0 ||
      index > 0 && current.newExerciceId !== array[index-1].newExerciceId
    ) {
      acc[key] = Math.max(...exercice);
    }
    return acc;
  }, {});
}

export const getMaxContentId = (maxContentIds, indices, finalTestId) => {
  let key;
  if (indices.activityId === finalTestId) { // for final test
    key = parseInt(indices.exerciceId) < 10 ?
    `${indices.moduleId}00${indices.pathId}00${finalTestId}00${indices.exerciceId}` :
    `${indices.moduleId}00${indices.pathId}00${finalTestId}0${indices.exerciceId}`;
  } else {
    key = parseInt(indices.exerciceId) < 10 ?
    `${indices.moduleId}00${indices.pathId}00${indices.activityId}00${indices.exerciceId}` :
    `${indices.moduleId}00${indices.pathId}00${indices.activityId}0${indices.exerciceId}`;
  }
    // console.log('In getMaxContentId', key);
  // console.log('In getMaxContentId', maxContentIds);
  return maxContentIds[key]
}

export const getMaxExerciceIds = (exos) => {
  if (exos && exos.length && exos.length > 0) {
    return exos.reduce((acc, current) => {
      const activity = exos
        .filter(item => (
          item.grade === current.grade &&
          item.module === current.module &&
          item.path === current.path &&
          item.activity === current.activity
        ))
        .map(item => item.exercice);
      const key = parseInt(current.activity) < 10 ?
        `${current.module}00${current.path}00${current.activity}` :
        `${current.module}00${current.path}0${current.activity}`;
      acc[key] = Math.max(...activity);
      return acc;
    }, {});
  }
  else {
    return null;
  }
}

export const getMaxExerciceId = (maxExerciceIds, indices) => {
  const key = parseInt(indices.activityId) < 10 ?
  `${indices.moduleId}00${indices.pathId}00${indices.activityId}` :
  `${indices.moduleId}00${indices.pathId}0${indices.activityId}`;
  return maxExerciceIds[key];
}

export const getMaxActivityIds = (activities) => {
  if (activities && activities.length && activities.length > 0) {
    return activities.reduce((acc, current) => {

      const path = activities
        .filter(item => (
          item.grade === current.grade &&
          item.module === current.module &&
          item.path === current.path
        ))
        .map(item => item.activity);

      const key = parseInt(current.path) < 10 ?
      `${current.module}00${current.path}` :
      `${current.module}0${current.path}`;
      
      acc[key] = Math.max(...path);
      return acc;
    }, {});
  }
  else {
    return null;
  }
}

export const getMaxActivityId = (maxActivityIds, indexes, pathId) => {
  const path = (indexes.pathId > pathId) ? indexes.pathId : pathId;
  const key = parseInt(pathId) < 10 ?
  `${indexes.moduleId}00${path}` :
  `${indexes.moduleId}0${path}`;
  return maxActivityIds[key];
}

export const addExoKey = item => {
  const path = (item.path < 10) ? `00${item.path}` : `0${item.path}`;
  const activity = (item.activity < 10) ? `00${item.activity}` : `0${item.activity}`;
  const exercice = (item.exercice < 10) ? `00${item.exercice}` : `0${item.exercice}`;
  const exoKey = `${item.module}${path}${activity}${exercice}`;
  return {
    ...item,
    exoKey
  }
}; 

export const numberExercices = (exercices) => exercices.reduce((acc, current, index, array) => {
  if (
    index === 0 ||
    current.newExerciceId !== array[index-1].newExerciceId
  ) {
    acc += 1
  }
  return acc;
}, 0);

export const numberActivities = (activities, indexes) => activities.filter(act =>
  act.module === indexes.moduleId &&
  act.path === indexes.pathId
).length;