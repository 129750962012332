import React, { useContext, useEffect, useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import { Button, Card } from 'antd';
import Activity from '../../containers/Activity';
import { StoreContext } from '../../containers/Store';

import * as ROLES from '../../constants/roles';
import useParseLocation from '../../hooks/useParseLocation';
import * as ROUTES from '../../constants/routes';

import '../../scss/ChatbotStudent.scss'

const getFinalTestId = (activities, pathNumber) => {
  const currentPathActivities = activities.filter(a => a.path == pathNumber);
  return currentPathActivities.length + 1;
}

const getBreadcrumbActivity = (activityId, finalTestId) => {
  switch(activityId) {
    case 0:
      return "Test diagnostique";
    case finalTestId:
      return "Test final";
    default:
      return "Activité " + activityId;
  } 
}

const Chatbot = (props) => {
  const {
    user: {
      user
    },
    data: {
      history: { activitiesState },
      sequence
    }, 
    cms: {
      modules,
      modeemplois,
      activities,
    },
    drawer: {
      stepId,
    },
  } = useContext(StoreContext);
  
  const { subject, grade, modul, path: pathId, activityId } = useParseLocation();

  const [startChatbot, setStartChatbot] = useState(true);
  const regex = /\d+/g;
  const moduleNumber = modul.match(regex);

  useEffect(() => {
    console.log('CHATBOT MOUNTED', modul, moduleNumber);
    
    return () => {
      console.log('CHATBOT UNMOUNTED');
    }
  }, []);

  useEffect(() => {
    if (activitiesState && activitiesState[0]) {
      setStartChatbot(false)
    }
  }, [activitiesState]);

  const finalTestId = getFinalTestId(activities, pathId);
  const breadcrumbParcours = (pathId > 0) 
      ? <span>Parcours {pathId}</span>
      : '';
  const breadcrumbActivity = getBreadcrumbActivity(parseInt(activityId), finalTestId);

  return (
    <div className="chatbot">
      <div className="breadcrumb"><span>Module {moduleNumber[0]}</span> > {breadcrumbParcours} {breadcrumbParcours? "> " : ""} <span> {breadcrumbActivity} </span></div>
      { startChatbot && user.roles.includes(ROLES.STUDENT) &&
      
        <Fragment>

          <div className="card">
            <h1 className="title">{!!modules && !!modules.length && modules[0].name || ''}</h1>
            <div className="content">
              <p>{`Objectif : ${!!modules && !!modules.length && modules[0].objective || ''}`} </p>
              <p>{`Programme : ${!!modules && !!modules.length && modules[0].description || ''}`} </p>
            </div>
          </div>

          {!!modeemplois.length &&
            <div className="card">
              <div className="content">
                <p>{!!modeemplois.length && modeemplois[0].description || ''}</p>
                {startChatbot && <Button type="primary" onClick={() => setStartChatbot(false)}> Démarrer </Button>}
              </div>
            </div>}

        </Fragment>}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {startChatbot && user.roles.includes(ROLES.TEACHER) && <Button type="primary" onClick={() => setStartChatbot(false)}> Démarrer </Button>}
        </div>

        { 
          !startChatbot &&
          activitiesState &&
        (
          !sequence ||
          !sequence[pathId] ||
          !sequence[pathId][activityId]
        ) && 
        user.roles.includes(ROLES.STUDENT) &&
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          <Button
            type="primary"
            onClick={() => {
              window.location.replace(`/${subject}/${grade}/${modul}/${pathId}/${activityId}/${ROUTES.CHATBOT}`);
            }}
          > Commencer l'activité
        </Button>
      </div>
      }
      {!startChatbot && <Activity />}

    </div>
  )

};

export default withRouter(Chatbot);