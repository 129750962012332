import React,{useState, useEffect} from 'react';
import uniqid from 'uniqid';
import TextareaAutosize from 'react-textarea-autosize';

import '../scss/MissingValues.scss'

function MissingValues(props) {

    const [state, setState] = useState( [] )
    const [values, setValues] = useState( [] )

    const valChange = (e, index) =>{
        let val = e.currentTarget.value
        let arr = values
        arr[index] = val
        setValues(arr)
    }
    
    const submitHandler = () => {
        let answers = []
        values.forEach( val => {
            if(val){
                answers.push(val)
            }
        } )
        props.getAnswer(answers)
        
        
    }

    useEffect( () => {
        if(props.content){
            setState(props.content)
        }  
    },[])
    
    const items = state.map((val, index) => (
        <div 
            key={uniqid()}
            data-id={val}>
                { val === "__" ? <textarea spellCheck="false" value={ values[index] } onChange={ (e) => valChange(e, index) } /> : val}
        </div>
    ));
    
    return (
        <div className="missing-values-container">
            {items}
            <button className="btn btn-validate" onClick={submitHandler}>Valider</button>
        </div>
    )
}
 
export default MissingValues
