import React, { useContext } from 'react';
import { Drawer, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import Activity from '../containers/Activity';
import { StoreContext } from '../containers/Store';
import useParseLocation from '../hooks/useParseLocation';
import { numberActivities } from '../utils/arrayOperations';

import * as ROUTES from '../constants/routes';

const ActivityDrawerStudent = ({ history }) => {
  let {
    data: {
      history: { activitiesState },
      setKeys,
      indexes
    },
    drawer: {
      titleRightDrawer,
      visibleRightDrawer,
      setRightDrawer,
      stepId,
    },
    cms: {
      activities
    }
  } = useContext(StoreContext);

  const { subject, grade, modul, path: pathId } = useParseLocation();

  const numberActivitiesDone = activitiesState[pathId] ? Object.keys(activitiesState[pathId]).length : 0;

  return console.log('numberActivitiesDone', numberActivitiesDone, 'stepId', stepId) || (
    <Drawer
      title={titleRightDrawer}
      placement="right"
      width={window.innerWidth < 520 ? window.innerWidth-50: Math.floor(window.innerWidth / 2)}
      closable={false}
      onClose={() => setRightDrawer(false)}
      visible={visibleRightDrawer}
    >
      { 
        activitiesState &&
        (stepId === 0) &&
        !activitiesState[0] && 
        <Button
          style={{ maxWidth: '300px', margin: 'auto', marginTop: '16px'}}
          type="primary"
          onClick={() => {
            setKeys(['1']);
            window.location.replace(`/${subject}/${grade}/${modul}/${pathId}/${stepId}/${ROUTES.CHATBOT}`);
          }}
        >
          Commencer le test
        </Button> 
      }
      { 
        activitiesState &&
        (stepId === 0) &&
        activitiesState[0] && 
        <Activity />
      }
      { 
        activitiesState &&
        (stepId > 0) &&
        activitiesState[pathId] &&
        activitiesState[pathId][stepId] &&
        <>
          <Activity  />
          {/* <Button
            style={{ maxWidth: '300px', margin: 'auto', marginTop: '16px'}}
            type="primary"
            onClick={() => {
              setKeys(['1']);
              window.location.replace(`/${subject}/${grade}/${modul}/${pathId}/${stepId}/${ROUTES.CHATBOT}`);
            }}
          >
            Reprendre
          </Button>  */}
        </>
      }    
      { 
        activitiesState &&
        (stepId > 0) &&
        (
          !activitiesState[pathId] && stepId === 1 && parseInt(pathId) > 0 ||
          activitiesState[pathId] && stepId > 1 && activitiesState[pathId][stepId-1] &&
          !activitiesState[pathId][stepId]
        ) &&
        <Button
          type="primary"
          onClick={() => {
            setRightDrawer(false);
            setKeys(['1']);
            window.location.replace(`/${subject}/${grade}/${modul}/${pathId}/${stepId}/${ROUTES.CHATBOT}`);
          }}
        > Commencer l'activité
      </Button>
      }
      {
        activitiesState &&
        (stepId > 0) &&
        (
          stepId === 1 && parseInt(pathId) === 0
        ) &&
        <p> Vous devez commencer par le test diagnostique </p>
      }
      { 
        activitiesState &&
        (stepId > 0) &&
        (
          stepId > 1 && activitiesState[pathId] && !activitiesState[pathId][stepId-1] ||
          stepId > 1 && !activitiesState[pathId] && stepId - 1 < numberActivities(activities, indexes)
        ) &&
        <p> Vous devez terminer l'activité précédente </p>
      }
      { 
        activitiesState &&
        stepId === numberActivities(activities, indexes) + 1 &&
        (
          !activitiesState[pathId] ||
          activitiesState[pathId] && (activitiesState[pathId].length < numberActivities(activities, indexes))
        ) &&
        <p> Vous devez terminer l'ensemble des activités </p>
      }
      { 
        activitiesState &&
        stepId === numberActivities + 1 &&
        activitiesState[pathId] &&
        (activitiesState[pathId].length === numberActivities(activities, indexes)) &&
        <Button
          type="primary"
          onClick={() => {
            setRightDrawer(false);
            setKeys(['1']);
            window.location.replace(`/${subject}/${grade}/${modul}/${pathId}/${stepId}/${ROUTES.CHATBOT}`);
          }}
        > Commencer le test
        </Button>
      }
    </Drawer>
  )
};

export default withRouter(ActivityDrawerStudent);
