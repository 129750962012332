import React, { useContext, useEffect, useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { StoreContext } from './Store';
import ErrorBanner from '../components/ErrorBanner';
import * as Sentry from '@sentry/browser';

import '../scss/GameLoop.scss';
import useInterval from '../hooks/useInterval';
import useParseLocation from '../hooks/useParseLocation';

import {
  needsUserInput,
  endExercice,
  endActivity,
  endPath,
  endModule,
} from '../utils/gameLoop';
import { correctAnswersInARow } from '../utils/stats'; 

import { NO_ACTIVITY_ERROR_MESSAGE, OFFLINE_MESSAGE } from '../constants/errorMessages';
import { 
  arrIsEmpty, 
  getMaxContentId,
  getMaxContentIds,
  getMaxExerciceId,
  getMaxExerciceIds,
  getMaxActivityId,
  getMaxActivityIds,
  objIsEmpty,
  numberExercices
} from '../utils/arrayOperations';

import * as ROLES from '../constants/roles';

const GameLoop = ({
  children,
 }) => {
  const {
    user: {
      user
    },
    data: {
      indexes,
      dispatchIndexes,
      sequence,
      dispatchSequence,
      answers,
      ia,
      dispatchIA
    },
    cms: {
      activities
    },
    drawer: {
      stepId: activityTeacher,
      pathId: pathTeacher,
    },
    feedback: {
      sentryId
    }
  } = useContext(StoreContext);

  const { path: pathStudent, activityId: activityStudent } = useParseLocation();
  let pathId, activityId;
  
  if (user && user.roles.includes(ROLES.STUDENT)) {
    pathId = pathStudent;
    activityId = activityStudent;
  } else if (user && user.roles.includes(ROLES.TEACHER)) {
    pathId = pathTeacher;
    activityId = activityTeacher;
  }

  const [lastItem, setLastItem] = useState();
  const [errorLoadingActivities, setErrorLoadingActivities] = useState(false);
  const [navigatorIsOffline, setNavigatorIsOffline] = useState(false);

  useEffect(() => {
    if (!sequence[pathId] || arrIsEmpty(sequence[pathId][activityId])) {
      setLastItem({});
    } else {
      setLastItem(sequence[pathId][activityId].slice(-1)[0]);
    }
  }, [sequence]);

  const finalTestId = activities.filter(act =>
    act.module === indexes.moduleId &&
    act.path === indexes.pathId
  ).length+1;

  //correct_answer is used by test bots;
  window.correct_answer = lastItem ? lastItem.correct_answer: '';

  useInterval(() => {
    setNavigatorIsOffline(navigator.onLine === false);

    if (
      !needsUserInput(lastItem.type) &&
      (
        user && user.roles.includes(ROLES.STUDENT) ||
        user && user.roles.includes(ROLES.TEACHER) &&
        (
          activityId === 0 || 
          !lastItem.content ||
          !lastItem.content.includes('📖')
        )
      )
    ) {

      if (ia.activity && ia.activity.length > 0) {
        setErrorLoadingActivities(false);
        const maxContentIds = getMaxContentIds(ia.activity, indexes, finalTestId);
        const maxContentId = getMaxContentId(maxContentIds, indexes, finalTestId);
        const maxExerciceIds = getMaxExerciceIds(ia.activity);
        const maxActivityIds = getMaxActivityIds(activities);
        // console.log('maxContentIds', maxContentIds);
        // console.log('maxContentId', maxContentId);
        if (!endExercice(indexes.contentId, maxContentId)) {
          // nextContent logic
          // console.log(indexes.contentId);
          // console.log(maxContentId);
          dispatchIndexes({
            type: 'NEXT_CONTENT', payload: {}
          });
        } else {
          let maxExerciceId = getMaxExerciceId(maxExerciceIds, indexes, finalTestId);
          if (parseInt(finalTestId) === finalTestId ) {
            maxExerciceId = numberExercices(ia.activity);
          }
          if (!endActivity(indexes.exerciceId, maxExerciceId)) {
            if (
              parseInt(activityId) === 0 || parseInt(activityId) === finalTestId // test diagnostique ou test final
            ) {
              dispatchIndexes({
                type: 'NEXT_EXERCICE', payload: {}
              });
            } else {
              const activityAnswers = answers[pathId] && answers[pathId][activityId] ? answers[pathId][activityId] : [];  
              if (
                correctAnswersInARow(activityAnswers, 4) &&
                user && user.roles.includes(ROLES.STUDENT)
              ) {
                dispatchIA({ type: 'FEEDBACK_SUCCESS_ROW', payload: {}});
              } else {
                dispatchIA({ type: 'FEEDBACK_EXERCICE', payload: {}});
              }
            } 
          } else {
            let maxActivityId = getMaxActivityId(maxActivityIds, indexes, ia.pathId || pathId);
            if (
              !endPath(indexes.activityId, maxActivityId) &&
              ia && ia.action !== 'FEEDBACK_ACTIVITY' &&
              parseInt(activityId) !== finalTestId
            ) {
              dispatchIA({ type: 'FEEDBACK_EXERCICE', payload: {}});
            } else if (parseInt(activityId) === finalTestId) {
              dispatchIA({ type: 'FEEDBACK_FINAL_TEST', payload: {}});
            } else {
              const maxPathId = 3; // will change if more than one path, but in the end, we will retrieve IA recommendation
              if (
                !endModule(indexes.pathId, maxPathId) &&
                ia && ia.action !== 'FEEDBACK_PATH'
              ) {
                
                dispatchIA({ type: 'FEEDBACK_ACTIVITY', payload: {}});
              } else {
                console.log('Should go to next module');
              }
            }
          }
        }
      }
      else {
        //TODO : ia.activity est vide, les exercices ne sont pas chargés
        // -> il faut donner un feedback à l'utilisateur
        setErrorLoadingActivities(true);
      }
    } else {
      if (
        user && user.roles.includes(ROLES.TEACHER) &&
        (
          !lastItem ||
          !lastItem.content ||
          lastItem && lastItem.content && lastItem.content.includes('📖')
        )
      ) {
        dispatchIndexes({ type: 'SET_INDEXES', payload: { ...indexes } });
      }
    }
  }, 1000);

  // setInstruction for main activities
  useEffect(() => {
    console.log("Gameloop, set instruction called")
    if (
      indexes &&
      indexes.exerciceId === 1 &&
      indexes.activityId > 0 &&
      parseInt(activityId) !== finalTestId &&
      sequence &&
      user && user.roles.includes(ROLES.STUDENT)
    ) {
      let content = activities
        .filter(act =>
          act.module === indexes.moduleId &&
          act.path === parseInt(pathId) &&
          act.activity === parseInt(activityId)
        )
        .map(item => item.instruction)[0]
      const instruction = {
        content,
        type: 'message',
      };
      dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: instruction, pathId, activityId } });
    }
  }, []);

  //setInstructions for final test
  useEffect(() => {
    if (
      indexes &&
      ia &&
      ia.activity &&
      (
        parseInt(activityId) === finalTestId
      ) &&
      user && user.roles.includes(ROLES.STUDENT)
    ) {
      console.log('Should find instruction here');
      const instruction = ia.activity.filter(exo => 
        exo.activity &&
        exo.newExerciceId === indexes.exerciceId &&
        exo.content_id === 0
      ).map(mess => activities
          .filter(act =>
            act.module === mess.module &&
            act.path === mess.path &&
            act.activity === mess.activity
          ).map(act => ({
            content: act.instruction,
            type: 'message'
          }))[0]
      )[0];
      dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: instruction, pathId, activityId } });
    }
  }, [ia.activity, indexes.exerciceId])

  // setContent
  useEffect(() => {
    if (
      ia &&
      ia.activity &&
      indexes.exerciceId > 0 &&
      user && user.roles.includes(ROLES.STUDENT)
    ) {
      let content = ia.activity.filter(item => {
        return item.newExerciceId === indexes.exerciceId &&
        item.newContentId === indexes.contentId
      })[0];
      if (!objIsEmpty(content)) {
        // light content cleaning if needed
        if (content.type === 'highlight_text') {
          const cleanContent = content.content.replace('[', '').replace(']', '');
          content = {
            ...content,
            content: cleanContent
          }
        } else if (content.type === 'drag') {
          const cleanContent = content.content.trim();
          content = {
            ...content,
            content: cleanContent
          } 
        }
        dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content, pathId, activityId } });
      }
    } 
  }, [indexes, ia.activity, activityId]);

  // setInstruction for main activities
  useEffect(() => {
    if (
      indexes &&
      indexes.exerciceId === 1 &&
      indexes.activityId > 0 &&
      parseInt(activityId) !== finalTestId &&
      sequence &&
      user && user.roles.includes(ROLES.TEACHER)
    ) {
      console.log('indexes in setInstruction', indexes);
      let content = activities
        .filter(act =>
          act.module === indexes.moduleId &&
          act.path === parseInt(pathId) &&
          act.activity === parseInt(activityId)
        )
        .map(item => item.instruction)[0]
      const instruction = {
        content,
        type: 'message',
      };
      dispatchSequence({ type: 'RESET_SEQUENCE', payload: {} });
      dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: instruction, pathId, activityId } });
    }
  }, [activityId, pathId]);

  // setContent for classic activities
  useEffect(() => {
    if (
      ia &&
      ia.activity &&
      indexes.exerciceId > 0 &&
      user && user.roles.includes(ROLES.TEACHER)
    ) {
      let content = ia.activity.filter(item => {
        return item.newExerciceId === indexes.exerciceId &&
        item.newContentId === indexes.contentId
      })[0];
      console.log('ia.activity', ia.activity);
      if (!objIsEmpty(content)) {
        // light content cleaning if needed
        if (content.type === 'highlight_text') {
          const cleanContent = content.content.replace('[', '').replace(']', '');
          content = {
            ...content,
            content: cleanContent
          }
        } else if (content.type === 'drag') {
          const cleanContent = content.content.trim();
          content = {
            ...content,
            content: cleanContent
          } 
        }
        // to-do refacto the whole gameloop component as a hook => two differents hooks for student and teacher
        // in the meantime, a quick and dirty fix for content that was repeating itself in teacher gallery
        if (
          content.path === indexes.pathId &&
          content.activity === indexes.activityId &&
          (
            (!sequence || !sequence[indexes.pathId] || !sequence[indexes.pathId][indexes.activityId]) ||
            (
              sequence && sequence[indexes.pathId] && sequence[indexes.pathId][indexes.activityId] &&
              sequence[indexes.pathId][indexes.activityId].slice(-1)[0] && 
              content.content !== sequence[indexes.pathId][indexes.activityId].slice(-1)[0].content
            )
          )
        )
        dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content, pathId, activityId } });
      }
    } 
  }, [indexes]);

  // set instructions for final Test
  useEffect(() => {
    if (
      indexes &&
      ia &&
      ia.activity &&
      (
        parseInt(activityId) === finalTestId
      ) &&
      user && user.roles.includes(ROLES.TEACHER)
    ) {
      console.log('Should find instruction here');
      const instruction = ia.activity.filter(exo => 
        exo.activity &&
        exo.newExerciceId === indexes.exerciceId &&
        exo.content_id === 0
      ).map(mess => activities
          .filter(act =>
            act.module === mess.module &&
            act.path === mess.path &&
            act.activity === mess.activity
          ).map(act => ({
            content: act.instruction,
            type: 'message'
          }))[0]
      )[0];
      dispatchSequence({ type: 'RESET_SEQUENCE', payload: {} });
      dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: instruction, pathId, activityId } });
    }
  }, [pathId, activityId, ia.activity, indexes.exerciceId])

    // setContent for classic activities
    useEffect(() => {
      if (
        ia &&
        ia.activity &&
        indexes.exerciceId > 0 &&
        (
          parseInt(activityId) === finalTestId
        ) &&
        user && user.roles.includes(ROLES.TEACHER)
      ) {
        console.log('In FInalTest ia.activity', ia.activity);
        console.log('In FInalTest indexes', indexes);

        let content = ia.activity.filter(item => {
          return item.newExerciceId === indexes.exerciceId &&
          item.newContentId === indexes.contentId
        })[0];
        if (!objIsEmpty(content)) {
          // light content cleaning if needed
          if (content.type === 'highlight_text') {
            const cleanContent = content.content.replace('[', '').replace(']', '');
            content = {
              ...content,
              content: cleanContent
            }
          } else if (content.type === 'drag') {
            const cleanContent = content.content.trim();
            content = {
              ...content,
              content: cleanContent
            } 
          }
          console.log('In FInalTest, content', content);
          if (content.exercice > 10) dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content, pathId, activityId } });
        }
      } 
    }, [indexes]);

  let errorMessage = '';
  if (navigatorIsOffline) {
    errorMessage = OFFLINE_MESSAGE;
  }
  else if (errorLoadingActivities) {
    errorMessage = NO_ACTIVITY_ERROR_MESSAGE;
  }

  console.log('HEEEERE, sentryId', sentryId)

  return (
    <Fragment>
      { errorMessage ? 
        <ErrorBanner message={ errorMessage }/>
        :''}
      { children }
      
      { sentryId ? 
        <button class="ant-btn ant-btn-primary report-btn" onClick={() => Sentry.showReportDialog({ eventId: sentryId, lang: 'fr' })}>Vous rencontrez une erreur sur cette page ?</button>
        :''
      }
    </Fragment>
  )
}

export default withRouter(GameLoop);
