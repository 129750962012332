import React, { createContext, useState, useEffect, useReducer } from 'react';
import useAuth from '../hooks/useAuth';
import useApi from '../hooks/useApi';
import useParseLocation from '../hooks/useParseLocation';
import useStore from '../hooks/useStore';
import * as TOKENS from '../constants/tokens';
import { 
  indexesReducer,
  loadingReducer,
  errorReducer,
  sequenceReducer,
  lastIndexesReducer,
  answersReducer,
  iaReducer,
  historyReducer,
  statisticsReducer,
  demoReducer
} from '../containers/reducers';
import * as Sentry from '@sentry/browser';

import {
  FR_KEY
} from '../constants/contentMap';

import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';

export const StoreContext = createContext(null);

export const StoreProvider = ({ children }) => {

  const [loading, dispatchLoading] = useReducer(loadingReducer, { auth: true, strapi: true, history: true });
  const [error, dispatchError] = useReducer(errorReducer, null);
  const [demo, dispatchDemo] = useReducer(demoReducer, { demo : false })

  const { serverAuth, strapiAuth } = useAuth();
  const { subject, grade, modul, path, activityId, page, error404 } = useParseLocation();

  const modId = modul ? parseInt(modul.replace('module','')) : 1;
  const { modules, paths, activities, exercices, modeemplois, getData, readHistory, readHistoryAll, readTracking, readTrackingAll } = useApi({
    subject,
    grade,
    modId
  });

  useEffect(() => {
    console.log('STORE IS MOUNTED');
    return () => console.log('STORE UNMOUNTED');
  }, []);

  // data
  // from url, get discipline, grade and module
  const subjectId = FR_KEY[subject];
  const gradeId = FR_KEY[grade];

  // data - indexes
  const initialIndexes = (
    !path && !activityId ||
    parseInt(path) === 0 &&
    parseInt(activityId) === 0
  ) ? {
    localeId: 'fr_FR',
    subjectId,
    gradeId,
    moduleId: modId,
    pathId: 0,
    activityId: 0,
    exerciceId: 0,
    contentId: 0, 
  } : {
    localeId: 'fr_FR',
    subjectId,
    gradeId,
    moduleId: modId,
    pathId: parseInt(path),
    activityId: parseInt(activityId),
    exerciceId: 1,
    contentId: 0, 
  };

  const [indexes, dispatchIndexes] = useReducer(indexesReducer, initialIndexes);
  const [lastIndexes, dispatchLastIndexes] = useReducer(lastIndexesReducer, null);

  useEffect(() => {
    if (indexes) {
      dispatchLastIndexes(indexes);
    } 
  }, [indexes]);

  // data - sequence
  const [sequence, dispatchSequence] = useReducer(sequenceReducer, [[[]]]);

  const deleteItemPromise = (lastItem, pathId, activityId, itemId) => {
    return new Promise((resolve, reject) => {
      dispatchSequence({
        type: 'DELETE_ITEM',
        payload: { lastItem, pathId, activityId, itemId }
      });
      resolve({
        type: 'DELETE_ITEM'
      });
    });
  }

  const [sentryId, setSentryIdFeedback] = useState(null);
  const handleError = (errorData) => {
    const eventId = Sentry.captureException(errorData.error);

    if (errorData.toDispatchError && errorData.toDispatchError.length) {
      if (errorData.toDispatchError.length) { // array of dispatches 
        errorData.toDispatchError.forEach(e => dispatchError(e));
      }
      else { //single dispatch
        dispatchError(errorData.toDispatchError);
      }
    }
    if (errorData.toDispatchLoading) {
      if (errorData.toDispatchLoading.length) { // array of dispatches 
        errorData.toDispatchLoading.forEach(e => dispatchLoading(e));
      }
      else { //single dispatch
        dispatchLoading(errorData.toDispatchLoading);
      }
    }
    setSentryIdFeedback(eventId);
  }

  const [answers, dispatchAnswers] = useReducer(answersReducer, [[[]]]);
  const [ia, dispatchIA] = useReducer(iaReducer, {});
  const [history, dispatchHistory] = useReducer(historyReducer, { activitiesState: {}});

  useEffect(() => {
    console.log(modId);
      serverAuth()
        .then((loadingBool) => {

          dispatchLoading({ type: 'AUTH', payload: loadingBool });
          if (localStorage.getItem('hatier-token').includes('TOKENDEMO') && modId > 1) {
            handleError({
              error: "Vous devez vous authentifiez pour accéder à cette ressource",
              toDispatchError: { type: 'AUTH', payload: error },
              toDispatchLoading: { type: 'AUTH', payload: false }
            })
          } else if (localStorage.getItem('hatier-token')) {
            console.log('token hatier (GUIDUser) is : ', localStorage.getItem('hatier-token'));
            dispatchLoading({ type: 'AUTH', payload: false });

            const authUser = {
              hatierToken: localStorage.getItem('hatier-token'),
              userType: localStorage.getItem('hatier-userType')
            };

            authUser.roles = [];
            if (authUser.userType === 'student') {
              authUser.roles.push(ROLES.STUDENT);
            } else if (authUser.userType === 'teacher') {
              authUser.roles.push(ROLES.TEACHER);
            } else if (authUser.userType !== TOKENS.TOKEN_DEMO) {
              handleError({error: "Erreur de type utilisateur"});
            }

            storeUser(authUser);
          
            //TODO : c'est ici que quand on est pas bien authentifié 403
            // si demo, on appelle pas strapi auth

            if (localStorage.getItem('hatier-token') === TOKENS.TOKEN_DEMO) {
              dispatchDemo({type: 'SET_DEMO', payload: true});
              dispatchLoading({ type: 'STRAPI', payload: false });
            }
            else { 
              strapiAuth()
              .then(getData) 
              .then(() => console.log('token strapi is : ', localStorage.getItem('strapi-token')))
              .then(() => {
                console.log('all data imported');
                dispatchLoading({ type: 'STRAPI', payload: false });
              })
              .catch((error) => {
                handleError({
                  error: error,
                  toDispatchError: { type: 'STRAPI', payload: error },
                  toDispatchLoading: { type: 'STRAPI', payload: false }
                })
              })
            }
          } 
        }).catch((error) => {
          handleError({
            error: error, 
            toDispatchError: [{ type: 'AUTH', payload: error }, { type: 'STRAPI', payload: error }],
            toDispatchLoading: [{ type: 'AUTH', payload: false }, { type: 'STRAPI', payload: false }]
          });
        });
    return () => console.log('Auth is unmounted');
  }, [modId]);

  // get histories to populate sequence
  // useEffect(() => {
  //   if (user && user.roles.includes(ROLES.STUDENT)) {
  //     if (activities.length > 0 && indexes.localeId) {
  //       let count = 0;
  //       dispatchLoading({ type: 'HISTORY', payload: true });
  //       activities.forEach(act => {
  //         // readHistory('fr_FR', FR_KEY[subject], FR_KEY[grade], parseInt(modul), act.path, act.activity)
  //         // here, because not possible in reducer :(
  //         // https://gist.github.com/astoilkov/013c513e33fe95fa8846348038d8fe42
  //         readHistory(
  //           indexes.localeId,
  //           indexes.subjectId,
  //           indexes.gradeId,
  //           indexes.moduleId,
  //           act.path,
  //           act.activity
  //         ).then((res) =>  {
  //           count += 1;
  //           // console.log('In STORE', count);
  //           // console.log('readHistory resolved', act.path, act.activity);
  //           // console.log(res);
  //           if (res && res.sequence) {
  //             //console.log('res.data', res);
  //             dispatchSequence({
  //               type: 'POPULATE_SEQUENCE',
  //               payload: {
  //                 sequence: JSON.parse(res.sequence),
  //                 pathId: act.path,
  //                 activityId: act.activity
  //               }
  //             });
  //             dispatchHistory({ type: 'ACTIVITIES', payload: { pathId: act.path, activityId: act.activity } });
  //             dispatchLastIndexes({ type: 'SET', payload: { pathId: act.path, activityId: act.activity } });
  //           }

  //           if (count === activities.length) {
  //             console.log('readHistory resolved for all activities', act.path, act.activity);
  //             dispatchLoading({ type: 'HISTORY', payload: false });
  //           }

  //         })
  //         .catch((error) => {
  //           console.log('readHistory catch', act.path, act.activity, error);
  //           Sentry.captureException(error);
  //           dispatchLoading({ type: 'HISTORY', payload: false });
  //           dispatchError({ type: 'HISTORY', payload: error });
  //         })
  //       });
  //     }
  //   } else {
  //     dispatchLoading({ type: 'HISTORY', payload: false });
  //   }
  // }, [activities, modId]);

  // getHistoryAll to populate sequence
  useEffect(() => {
    if (user && user.roles.includes(ROLES.STUDENT)) {
      const GUIDUser = localStorage.getItem('hatier-token');
      dispatchLoading({ type: 'HISTORY', payload: true });
      readHistoryAll(
        GUIDUser
      ).then((res) => {
        if (res.results) {
          console.log('res.data', res.results);
          res.results
            .filter(act =>
              act.module === modId
            )
            .sort((a, b) => a.activity - b.activity)
            .sort((a, b) => a.path - b.path)
            .forEach(act => {
              console.log('POPULATE SEQUENCE for act', act.path, act.activity);
              dispatchHistory({ type: 'ACTIVITIES', payload: { pathId: act.path, activityId: act.activity } });
              dispatchLastIndexes({ type: 'SET', payload: { pathId: act.path, activityId: act.activity } });
              dispatchSequence({
                type: 'POPULATE_SEQUENCE',
                payload: {
                  sequence: JSON.parse(act.sequence),
                  pathId: act.path,
                  activityId: act.activity
                }
              });
            });
            dispatchLoading({ type: 'HISTORY', payload: false });
        }
      })
      .catch((error) => {
        console.log('readHistory catch', error);
        dispatchLoading({ type: 'HISTORY', payload: false });
        dispatchError({ type: 'HISTORY', payload: error });
      })
    } else {
      dispatchLoading({ type: 'HISTORY', payload: false });
    }
  }, [activities, modId]);

  // get trackings to populate answer
  // useEffect(() => {
  //   if (user && user.roles.includes(ROLES.STUDENT)) {
  //     if (activities.length > 0 && indexes.localeId) {
  //       let count = 0;
  //       dispatchLoading({ type: 'TRACKING', payload: true });
  //       activities.forEach(act => {
  //         // readHistory('fr_FR', FR_KEY[subject], FR_KEY[grade], parseInt(modul), act.path, act.activity)
  //         // here, because not possible in reducer :(
  //         // https://gist.github.com/astoilkov/013c513e33fe95fa8846348038d8fe42
  //         readTracking(
  //           indexes.localeId,
  //           indexes.subjectId,
  //           indexes.gradeId,
  //           indexes.moduleId,
  //           act.path,
  //           act.activity
  //         ).then((res) =>  {
  //           count += 1;
  //           console.log('In STORE', count);
  //           console.log('readTracking resolved', act.path, act.activity);
  //           console.log(res);
  //           if (res.data && res.data.tracking) {
  //             console.log('res.data', res.data);
  //             const cleanAnswers = res.data.tracking.map(item => {
  //               let activityId = (!item.activityId) ? res.data.activity : item.activityId;
  //               let pathId = (!item.pathId) ? res.data.path : item.pathId;
  //               return ({
  //                 ...item,
  //                 activityId,
  //                 pathId
  //               })
  //             });
  //             console.log(cleanAnswers)
  //             dispatchAnswers({
  //               type: 'POPULATE_ANSWERS',
  //               payload: {
  //                 answers: cleanAnswers,
  //                 pathId: act.path,
  //                 activityId: act.activity
  //               }
  //             });
  //           }
  
  //           if (count === activities.length) {
  //             console.log('readTracking resolved for all activities', act.path, act.activity);
  //             dispatchLoading({ type: 'TRACKING', payload: false });
  //           }
  
  //         })
  //         .catch((error) => {
  //           console.log('readTracking catch', act.path, act.activity, error);
  //           Sentry.captureException(error);
  //           dispatchLoading({ type: 'TRACKING', payload: false });
  //           dispatchError({ type: 'TRACKING', payload: error });
  //         })
  //       });
  //     }
  //   } else {
  //     dispatchLoading({ type: 'TRACKING', payload: false });
  //   }
  // }, [activities, modId]);

  // quand getTrackingAll ok, remplacer part
  useEffect(() => {
    if (user && user.roles.includes(ROLES.STUDENT)) {
      const GUIDUser = localStorage.getItem('hatier-token');
      dispatchLoading({ type: 'TRACKING', payload: true });
      readTrackingAll(
        GUIDUser
      ).then((res) => {
        if (res.results) {
          console.log('res.results', res.results);
          res.results
            .filter(act =>
              act.module === modId
            )
            .forEach(act => {
              console.log('POPULATE ANSWERS for act', act.path, act.activity);
              dispatchAnswers({
                type: 'POPULATE_ANSWERS',
                payload: {
                  answers: JSON.parse(act.tracking),
                  pathId: act.path,
                  activityId: act.activity
                }
              });
            });
            dispatchLoading({ type: 'TRACKING', payload: false });
        }
      })
      .catch((error) => {
        console.log('readHistory catch', error);
        dispatchLoading({ type: 'TRACKING', payload: false });
        dispatchError({ type: 'TRACKING', payload: error });
      })
    } else {
      dispatchLoading({ type: 'TRACKING', payload: false });
    }
  }, [activities, modId]);

  const initUser = localStorage.getItem('evidenceB-user') ?
    JSON.parse(localStorage.getItem('evidenceB-user')) : null;
  const [user, storeUser] = useStore(initUser, 'user');

  const [visibleRightDrawer, setRightDrawer] = useState(false);
  const [titleRightDrawer, setRightDrawerTitle] = useState("Test diagnostique");
  const [stepId, setStepId] = useState(null);
  const [pathId, setPathId] = useState();
  const [reference, setReference] = useState([]);
  const [clickCount, setClickCount] = useState(0);

  const [statistics, dispatchStatistics] = useReducer(statisticsReducer, {});

  const mapKeys = (page) => {
    if (page === ROUTES.CHATBOT) {
      return ['1']
    } else if (page === ROUTES.PATH) {
      return ['2']
    } else if (page === ROUTES.DASHBOARD) {
      if (user && user.roles.includes(ROLES.STUDENT)) {
        return ['3']
      } else {
        return ['1']
      }
    } else if (page === ROUTES.GALLERY) {
      return ['2']
    }
  }
  useEffect(() => {
    console.log('STORE MOUNTED');
    // handleError({error:"errorTest"}); // Pour tester les erreurs, décommenter ici
    return () => {
      console.log('STORE UNMOUNTED');
    }
  }, []);

  const [keys, setKeys] = useState(mapKeys(page));
  // console.log('In Store', keys);
  let [finalScore, setFinalScore] = useState(0);

  const store = {
    user: {
      user,
      storeUser,
      lastIndexes,
      dispatchLastIndexes
    },
    data: {
      indexes,
      dispatchIndexes,
      sequence,
      dispatchSequence,
      deleteItemPromise,
      answers,
      dispatchAnswers,
      ia,
      dispatchIA,
      statistics,
      dispatchStatistics,
      history,
      keys,
      setKeys,
      finalScore,
      setFinalScore,
    },
    drawer: {
      visibleRightDrawer,
      setRightDrawer,
      titleRightDrawer,
      setRightDrawerTitle,
      stepId,
      setStepId,
      reference,
      setReference,
      pathId,
      setPathId,
      clickCount,
      setClickCount
    },
    error: {
      error,
      dispatchError
    },
    location: {
      subject,
      grade,
      modul,
      path,
      activityId
    },
    cms: {
      modules,
      paths,
      activities,
      exercices,
      modeemplois
    },
    loading: {
      loading,
      dispatchLoading
    },
    demo: {
      demo
    },
    feedback: {
      sentryId
    },
  };

  return (
    <StoreContext.Provider value={store}>
      { children }
    </StoreContext.Provider>
  );
};

export default StoreProvider;
