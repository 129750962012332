export const LANDING = "acceuil";
// export const SIGN_UP = '/signup';
// export const SIGN_IN = '/signin';
export const SPLASH = "";
export const DASHBOARD = 'journal';
export const ADMIN = 'admin';
export const LOGOUT = 'logout';
export const CHATBOT = 'chatbot';
export const PATH = 'parcours'; 
export const GALLERY = 'galerie';
export const STUDENT = 'eleve';
export const OTHERSTUDENT = 'autreeleve';
export const DEMO = 'demo';

// export const PASSWORD_FORGET = '/pw-forget';