import React from 'react';


export default () => (
  <div className="centered-container">
    <div>
        <h1 style={{ fontSize: 50 }}>
          Erreur d'authentification
        </h1>
        <h2>
          Erreur d'authentification au site de contenu.
        </h2>
        <h2>
          Veuillez contacter les administrateurs du site.
        </h2>
    </div>
  </div>
);
