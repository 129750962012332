import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import * as ROUTES from './constants/routes';
import * as ROLES from './constants/roles';

import Splash from "./pages/Splash";
import Demo from "./pages/Demo";
import ContentStudent from "./pages/ContentStudent";
import ContentTeacher from "./pages/ContentTeacher";

import StoreProvider, { StoreContext } from "./containers/Store";
import Layout from "./components/Layout";
import Logout from './components/Logout';
import Loading from './components/Loading';
import Error404 from './pages/Error404';
import Error403 from './pages/Error403';
import ErrorStrapi from "./pages/ErrorStrapi";

import "./scss/App.scss";
import './scss/global.scss'

const App = () => {

  const [nomatch, setNotmatch] = useState(false);

  useEffect(() => {
    console.log('APP IS MOUNTED');
    window.redirectToHatier = (document.referrer.includes('www.itineraireslitteraires.fr') 
      ? 'https://www.itineraireslitteraires.fr/deconnexion-page' : false);
    // mask logs in production
    if (process.env.NODE_ENV === 'production') console.log = () => {};
    if (!window.location.pathname.includes('francais/premieres/module') || window.location.pathname === '/francais/premieres/module') {
      setNotmatch(true);
      window.location.replace('/francais/premieres/module1')
    }
  }, []);

  return (
      <StoreProvider>
        <StoreContext.Consumer>
          {({
              user: {
                user
              },
              error: {
                error
              },
              loading: {
                loading
              },
              demo: {
                demo
              },
            }) => {
            if (error && error.auth) {
              window.location.replace(process.env.REACT_APP_ACCESLIBRE_URL + '/accueil.html');
            } else if (error && error.strapi) {
              return <ErrorStrapi />
            } else if (!Object.values(loading).every(item => !item)) {
              return <Loading />
            } else {
              return (
                <Router>
                  <Layout nomatch={nomatch}>
                    <Switch>
                      {/* <Route exact path={`/:subject/:grade/:modul/${ROUTES.DEMO}`} render={() => <Demo />} /> */}
                      {demo && demo.demo && <Route exact path={`/:subject/:grade/:modul`} render={() => <Demo />} />}
                      <Route exact path={`/:subject/:grade/:modul/${ROUTES.SPLASH}`} render={() => <Splash />} />
                      {/* if student */}
                      {user && user.roles.includes(ROLES.STUDENT) && <Route exact path={`/:subject/:grade/:modul/:path/:activityId/${ROUTES.LOGOUT}`} render={() => <Logout />} />}
                      {user && user.roles.includes(ROLES.STUDENT) && <Route path={`/:subject/:grade/:modul/:path/:activityId`} render={() => <ContentStudent />} />}
                      {/* if teacher */}
                      {user && user.roles.includes(ROLES.TEACHER) && <Route exact path={`/:subject/:grade/:modul/${ROUTES.LOGOUT}`} render={() => <Logout />} /> }
                      {user && user.roles.includes(ROLES.TEACHER) && <Route path={`/:subject/:grade/:modul`} render={() => <ContentTeacher />} />}
                      {user && <Route path={`/:subject/:grade`} render={() => <Redirect to={'/francais/premieres/module1'} />} />}
                      {!error && <Route component={Error404} />}
                    </Switch>
                  </Layout>
                </Router>
              )
            }
          }}
        </StoreContext.Consumer>
      </StoreProvider>
  );
};

export default App;