import React, { useContext, useEffect, useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { StoreContext } from './Store';
import useParseLocation from '../hooks/useParseLocation';
import { useError } from '../hooks/useStatistics';
import { useExercice } from '../hooks/useContent';
import {
  parseScore,
  randomizeExercices,
  selectFinalExercices,
  cleanAnswer,
  answerNumberOptions
} from '../utils/ia';
import { 
  arrIsEmpty,
  getMaxActivityId,
  getMaxActivityIds,
  numberExercices,
  numberActivities
} from '../utils/arrayOperations';

import * as ROLES from '../constants/roles';
import * as ANSWERS from '../constants/sequenceAnswers';


const IA = ({ 
  children,
}) => {
  const {
    user: {
      user
    },
    data: {
      indexes,
      sequence,
      answers,
      dispatchIA,
      dispatchSequence,
      ia,
      dispatchIndexes,
      history: { activitiesState },
      finalScore,
      setFinalScore
    },
    cms: {
      exercices,
      activities,
      paths
    },
    drawer: {
      stepId: activityTeacher,
      pathId: pathTeacher
    }
  } = useContext(StoreContext);


  const { modul, path: pathStudent, activityId: activityStudent } = useParseLocation();

  let pathId, activityId;

  if (user && user.roles.includes(ROLES.STUDENT)) {
    pathId = pathStudent;
    activityId = activityStudent;
  } else if (user && user.roles.includes(ROLES.TEACHER)) {
    pathId = pathTeacher;
    activityId = activityTeacher;
  }


  const finalTestId = activities.filter(act =>
    act.module === indexes.moduleId &&
    act.path === indexes.pathId
  ).length+1;

  // Test Diagnostique

  // init messages
  useEffect(() => {
    if (
      parseInt(activityId) === 0 &&
      indexes.exerciceId === 0 
    ) {
      let content;
      if (indexes.contentId === 0) {
        content = {
          content: "Bienvenue 🤗 Je vais vous poser quelques questions. Vos réponses vont me permettre d'élaborer l'entraînement qui vous correspond le mieux. À la fin du test, on ne vous note pas, on vous oriente vers votre parcours personnalisé.",
          type: "message",
          activity: 0,
          exercice: 0,
        };
      } else if (indexes.contentId === 1) {
        content = {
          content: 'Cliquez sur le bouton "On y va" quand vous êtes prêt !',
          type: "message",
          activity: 0,
          exercice: 0,
        };
      } else {
        content = {
          content: 'On y va 🚀',
          type: "button",
          activity: 0,
          exercice: 0,
          action: 'LAUNCH_ACTIVITY'
        };
      }
      
      dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content, pathId, activityId } });
      // if (shouldAddContent({ sequence, pathId, activityId, content, indexes, finalTestId })) dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content, pathId, activityId } });
    }
  }, [indexes, activityId, pathId]);

  // randomize exercices order
  useEffect(() => {
    if (parseInt(pathId) === 0) {
      const activity = exercices.filter(item => {
        return item.subject === indexes.subjectId &&
        item.grade === indexes.gradeId &&
        item.module === indexes.moduleId &&
        item.path === 0 &&
        item.activity === 0 &&
        item.exercice > 0 &&
        item.exercice <= 10 // exercices for final test
      });
      const shuffledActivity = randomizeExercices(activity);
      dispatchIA({ type: 'SHUFFLE_ACTIVITY', payload: { activity: shuffledActivity } });
    }
  }, [exercices, pathId]);

  const [scoreDiag, setScoreDiag] = useState({ path1: 0, path2: 0, path3: 0 });

  const [itemL1, setItemL1] = useState({});
  useEffect(() => {
    if (!sequence[pathId] || arrIsEmpty(sequence[pathId][activityId])) {
      setItemL1({});
    } else {
      const last = sequence[pathId][activityId].slice(-1)[0];
      setItemL1(last);
    }
  }, [sequence]);

  // score computation
  const [answerL1, setAnswerL1] = useState({});
  useEffect(() => {
    if (!answers[pathId] || arrIsEmpty(answers[pathId][activityId])) {
      setAnswerL1({});
    } else {
      const last = answers[pathId][activityId].slice(-1)[0];
      setAnswerL1(last);
    }
  }, [answers]);

  useEffect(() => {
    if (parseInt(pathId) === 0) {
      console.log('In IA, itemL1', itemL1);
      console.log('In IA, answerL1', answerL1);
      if (itemL1.difficulty) {
        const difficulty = parseScore(itemL1.difficulty);
        // const difficulty = itemL1.difficulty;
        const { isCorrect } = answerL1;
        console.log('difficulty', difficulty);
        console.log('typeof', typeof(difficulty));
        console.log('isCorrect', isCorrect);
        console.log('difficulty.path1', difficulty.path1);
        if (
          difficulty.path1 !== undefined &&
          isCorrect === false
        ) {
          scoreDiag.path1 += difficulty.path1;
          scoreDiag.path2 += difficulty.path2;
          scoreDiag.path3 += difficulty.path3;
          console.log('In IA {...scoreDiag }', {...scoreDiag });
          setScoreDiag({...scoreDiag });
        }
      }
    }
  }, [answerL1]);

  // path allocation
  useEffect(() => {
    if (ia && ia.activity) {
      if (parseInt(activityId) === 0) {
        const score = Object.values(scoreDiag).reduce((acc, current) => {
          acc = Math.max(acc, current);
          return acc;
        }, 0);
        console.log('score', score);
        const path = Object.keys(scoreDiag).filter(key => scoreDiag[key] === score);
        console.log('path', path);
        // we take lowest path if same score, except if not error at all : path3
        const recommendedPathId = (score === 0) ? 3 : parseInt(path[0].replace('path',''));
        console.log('recommendedPathId', recommendedPathId);
        
        if (
          ia.action === 'FEEDBACK_ACTIVITY' || ia.action === 'FEEDBACK_EXERCICE'
        ) {
          dispatchIA({ type: 'ALLOCATE_PATH', payload: { pathId: recommendedPathId } });
          console.log('In IA, endActivity');
          const content1 = {
            content: paths.filter(pth => pth.path === recommendedPathId)[0].difficulty_student,
            type: "feedback",
          };
          let id = setTimeout(() => {
            dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content1, pathId, activityId } });
          }, 1000);
          if (user && user.roles.includes(ROLES.STUDENT)) {
            const content2 = {
              content: "On s'entraîne ?",
              type: "button",
              action: "NEXT_ACTIVITY"
            };
            id = setTimeout(() => {
              dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content2, pathId, activityId } });
            }, 2000);
            return () => clearTimeout(id);
          }
        }
      }
    }
  }, [ia.activity, ia.action]);


  // Activity > 0
  // shuffle exercice activity
  useEffect(() => {
    if (parseInt(activityId) > 0) {
      const activity = exercices.filter(item => {
        return item.subject === indexes.subjectId &&
        item.grade === indexes.gradeId &&
        item.module === indexes.moduleId &&
        item.path === parseInt(pathId) &&
        item.activity === parseInt(activityId) &&
        item.exercice > 0 &&
        item.exercice <= 10 // exercices for final test
      });
      console.log('pathId in IA', pathId);
      console.log('activitiId in IA', activityId);
      const shuffledActivity = randomizeExercices(activity);
      dispatchIA({ type: 'SHUFFLE_ACTIVITY', payload: { activity: shuffledActivity } });
    }
  }, [exercices, indexes.pathId, indexes.activityId]);

  const exercice = useExercice(itemL1, exercices);
  const errors = useError(answers, 2, pathId, activityId);
  // console.log('useError(answers, 2, pathId, activityId)', errors);
  // exercice feedback
  useEffect(() => {
    // console.log('answerL1', answerL1);
    if (
      parseInt(activityId) > 0 &&
      ia && ia.action === 'FEEDBACK_EXERCICE' &&
      itemL1.type === 'answer'
    ) {      
      if (answerL1 && answerL1.isCorrect) {
        const content = {
          content: "Bien joué 👍",
          type: "feedback",
        };
        let id = setTimeout(() => {
          dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content, pathId, activityId } });
        }, 1000);
        id = setTimeout(() => {
          if (indexes && indexes.exerciceId < 10) { // à revoir, généraliser quel que soit le nombre d'exercice, repérer le dernier exercice
            dispatchIndexes({
              type: 'NEXT_EXERCICE', payload: {}
            });
          } else {
            dispatchIA({ type: 'FEEDBACK_ACTIVITY', payload: {} });
          }
        }, 2000);
        return () => clearTimeout(id);
      }
    
      else if (
        indexes.action === 'NEXT_CONTENT' &&
        (
          errors === 2 ||
          exercice && answerNumberOptions(exercice.lastItem) === 2
        )
      ) {

        const content = {
          content: `Oups 🤦‍. La bonne réponse est : ${cleanAnswer(exercice.lastItem)}`,
          type: "feedback",
        };
        let id = setTimeout(() => {
          dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content, pathId, activityId } });
        }, 1000);  
        id = setTimeout(() => {
          if (indexes && indexes.exerciceId < 10) { // à revoir, généraliser quel que soit le nombre d'exercice, repérer le dernier exercice
            dispatchIndexes({ type: 'NEXT_EXERCICE', payload: {} });
          } else {
            dispatchIA({ type: 'FEEDBACK_ACTIVITY', payload: {} });
          }
        }, 2000)    
        return () => clearTimeout(id);
      }
      
      else if (answerL1 && answerL1.isCorrect === false) { // ajouter une condition sur le test
        const content = {
          content: "Oups 🤦‍. Essayez encore !",
          type: "feedback",
        };
        let id = setTimeout(() => {
          dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content, pathId, activityId } });
        }, 1000);
        exercice.exercice.forEach((exo, index) => {
          if (exo.type === 'highlight_text') {
            const cleanContent = exo.content.replace('[', '').replace(']', '');
            exo = {
              ...exo,
              content: cleanContent
            }
          } else if (exo.type === 'drag') {
            const cleanContent = exo.content.trim();
            exo = {
              ...exo,
              content: cleanContent
            } 
          }
          id = setTimeout(() => {
            dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: exo, pathId, activityId } });
          }, (index+2)*1000);
        });
        return () => clearTimeout(id);
      }
    }
  }, [answerL1, ia.action]);

  // continue / next activity choice
  useEffect(() => {
    if (parseInt(activityId) > 0) {
      if (
        ia && ia.action === 'FEEDBACK_SUCCESS_ROW' &&
        user && user.roles.includes(ROLES.STUDENT)
      ) {
        const content1 = {
          content: "Je pense que vous avez compris. On change d'entraînement ?",
          type: "feedback",
        };
        let id = setTimeout(() => {
          dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content1, pathId, activityId } });
        }, 2000);
        const content2 = {
          content: [ANSWERS.CONTINUE_SEQUENCE, ANSWERS.VALIDATE_SEQUENCE],
          type: "choice",
        };
        id = setTimeout(() => {
          dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content2, pathId, activityId } });
        }, 3000);
        return () => clearTimeout(id);
      }
    }
  }, [answerL1, ia.action]);

  // activity feedback
  useEffect(() => {
    // console.log(ia);
    if (parseInt(activityId) > 0 && user && user.roles.includes(ROLES.STUDENT)) {
      if (
        ia &&
        ia.action === 'FEEDBACK_ACTIVITY' &&
        answerL1.type !== 'CHOICE' //for some reason, we had content from previous activity that was lagging when changing to another activity
      ) {
        // debugger;
        const maxActivityIds = getMaxActivityIds(activities);
        const maxActivityId = getMaxActivityId(maxActivityIds, indexes, ia.pathId || pathId);
        console.log(maxActivityId);
        if (parseInt(activityId) < maxActivityId) {
          const content1 = {
            content: "L'activité est terminée. On change d'entraînement ?",
            type: "message",
          };
          dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content1, pathId, activityId } });
          const content2 = {
            content: [ANSWERS.RESTART_SEQUENCE, ANSWERS.VALIDATE_SEQUENCE],
            type: "choice",
          };
          // debugger;
          let id = setTimeout(() => {
            dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content2, pathId, activityId } });
          }, 1000);
          return () => clearTimeout(id);
        } else {
          dispatchIA({ type: 'FEEDBACK_PATH', payload: {} });
        }
      }
    }
  }, [answerL1, ia.action])

  // Final Test
  useEffect(() => {
    if (
      activities &&
      parseInt(pathId) > 0 &&
      ia && ia.action === 'FEEDBACK_PATH' &&
      user && user.roles.includes(ROLES.STUDENT)
    ) {
      if (
        activitiesState &&
        activitiesState[pathId]
      ) {
        const allActivitiesDone = (Object.keys(activitiesState[pathId]).length+1 === numberActivities(activities, indexes));
         //+1 to add test diag
        if (allActivitiesDone) {
          // substr 13th exercice
          const content1 = {
            content: 'Bravo, vous avez terminé toutes les activités du parcours ! Je vous propose de vérifier vos acquis avec un test. Si vous obtenez plus de la moyenne, vous pourrez changer de parcours ou de module !',
            type: 'feedback'
          };
          let id = setTimeout(() => {
            dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content1, pathId, activityId } });
          }, 1000);
          const content2 = {
            content: 'Passer le test 🎓',
            type: 'button',
            action: 'FINAL_TEST'
          }
          id = setTimeout(() => {
            dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content2, pathId, activityId } });
          }, 2000);
          return () => clearTimeout(id);
        } else {
          const content1 = {
            content: 'Je vous invite à terminer les activités manquantes pour valider le parcours',
            type: 'feedback'
          };
          let id = setTimeout(() => {
            dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content1, pathId, activityId } });
          }, 1000);
          const content2 = {
            content: 'Terminer',
            type: 'button',
            action: 'FINISH_PATH'
          }
          id = setTimeout(() => {
            dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content2, pathId, activityId } });
          }, 2000);
        }
      }
    }
  }, [ia.action]);

  // launching final test
  useEffect(() => {
    if (parseInt(activityId) === finalTestId) {
      const finalTest = exercices.filter(
        item => {
          return (item.grade === indexes.gradeId &&
          item.module === indexes.moduleId &&
          item.path === parseInt(pathId) &&
          item.exercice > 10)
        }
      );
      const nbActivities = activities.filter(act => act.path === parseInt(pathId)).length;
      const selectedExercices = selectFinalExercices(finalTest, nbActivities, parseInt(modul.replace('module', '')), parseInt(pathId), 9)
      dispatchIA({ type: 'FINAL_TEST', payload: { activity: selectedExercices } });
    }
  }, [activityId]);

  const initErrorsPerActivity = activities
    .filter(act => act.path === parseInt(pathId))
    .map(act => act.activity)
    .reduce((acc, current) => {
      acc[current] = 0
      return acc;
    }, {});
  const [errorsPerActivity, setErrorsPerActivity] = useState(initErrorsPerActivity);

  useEffect(() => {
    // console.log(finalScore);
    // console.log(errorsPerActivity);
    if (
      parseInt(activityId) === finalTestId
    ) {
      console.log(answerL1);
      if (answerL1 && answerL1.isCorrect) {
        const fS = finalScore + 1;
        setFinalScore(fS);
      } else {
        if (
          errorsPerActivity &&
          answerL1 &&
          answerL1.activityId
        )
        errorsPerActivity[answerL1.activityId] += 1;
        setErrorsPerActivity(errorsPerActivity);
      }
    }
  }, [answerL1]);
  
  useEffect(() => {
    if (
      ia && ia.action === 'FEEDBACK_FINAL_TEST' &&
      ia.activity
    ) {
      console.log('Test final terminé');
      console.log('Moyenne à obtenir: ,', numberExercices(ia.activity) / 2);
      console.log('Score obtenu :', finalScore);
      if (
        finalScore > numberExercices(ia.activity) / 2
      ) {
        if (parseInt(pathId) < 3) {
          const content1 = {
            content: "Bravo, vous avez obtenu plus de la moyenne. Vous pouvez passer au parcours suivant !",
            type: 'feedback'
          };
          dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content1, pathId, activityId } });
          if (user && user.roles.includes(ROLES.STUDENT)) {
            const content2 = {
              content: "Parcours suivant",
              type: "button",
              action: "NEXT_PATH"
            };
            let id = setTimeout(() => {
              dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content2, pathId, activityId } });
            }, 1000);
            return () => clearTimeout(id);
          }
        } else {
          const modId = modul ? parseInt(modul.replace('module','')) : 1;
          if (parseInt(modId) === 9) {
            const content1 = {
              content: "Bravo, vous avez obtenu plus de la moyenne. Vous avez terminé le dernier parcours. Choisissez maintenant un nouveau module dans le menu déroulant.",
              type: 'feedback'
            };
            dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content1, pathId, activityId } });
          } else {
            const content1 = {
              content: "Bravo, vous avez obtenu plus de la moyenne. Vous avez terminé le dernier parcours. Choisissez maintenant un nouveau module dans le menu déroulant.",
              type: 'feedback'
            };
            dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content1, pathId, activityId } });
            /* No need for next module button, student has to choose next module from nav menu*/
            // if (user && user.roles.includes(ROLES.STUDENT)) {
            //   const content2 = {
            //     content: "Module suivant",
            //     type: "button",
            //     action: "NEXT_MODULE"
            //   };
            //   let id = setTimeout(() => {
            //     dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content2, pathId, activityId } });
            //   }, 1000);
            //   return () => clearTimeout(id);
            // }
          }
        }
      } else {
        console.log('errorsPerActivity', errorsPerActivity);
        // const shouldWorkActivities = Object.entries(errorsPerActivity)
        //   .filter(([k, v]) => v > 0)
        //   .map(([k, v]) => k)
        //   .join(', ');
        const maxErrorsNumber = Object.values(errorsPerActivity).reduce((acc, current) => {
          acc = Math.max(acc, current);
          return acc;
        }, 0);
        const nextActivityId = Object.keys(errorsPerActivity).filter(key => errorsPerActivity[key] === maxErrorsNumber)[0];
        const content1 = {
          content: `Je pense que vous avez besoin de poursuivre l'entraînement sur ce parcours. Je vous invite à refaire l'activité ${nextActivityId}`,
          type: 'feedback'
        };
        dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content1, pathId, activityId } });
        if (user && user.roles.includes(ROLES.STUDENT)) {
          const content2 = {
            content: "On s'entraîne ?",
            type: "button",
            action: "REDO_ACTIVITY",
            payload: errorsPerActivity
          };
          let id = setTimeout(() => {
            dispatchSequence({ type: 'ADD_MESSAGE', payload: { lastItem: content2, pathId, activityId } });
          }, 1000);
          console.log('content1', content1);
          console.log('nextActivityId', nextActivityId);
          return () => clearTimeout(id);
        }
      }
    }
  }, [ia.action, ia.activity])


  return (
    <Fragment>
      { children }
    </Fragment>
  )
};

export default withRouter(IA);