import React, { useState, useEffect } from 'react';

export const useExercice = (item, exercices) => {

  const [state, action] = useState();

  useEffect(() => {
    const exercice = exercices.filter(elem =>
      elem.subject === item.subject && 
      elem.grade === item.grade &&
      elem.module === item.module &&
      elem.path === item.path &&
      elem.activity === item.activity &&
      elem.exercice === item.exercice);
    // console.log([...exercice]);
    const lastItem = {...exercice.slice(-1)[0]};
    action({ exercice, lastItem, type: lastItem.type });
  }, [exercices, item])

  return state
}

// export const useExerciceLastItem = (item, items) => {
//   const [state, action] = useExercice(item, items);
//   // console.log('In useExerciceLastItem state:', state);
//   const lastExerciceItem = (state && state.length > 1) ? state.slice(-1)[0] : {};
//   // console.log('(state.length > 1)', (state.length > 1))
//   // console.log('lastExerciceItem', lastExerciceItem)
//   return lastExerciceItem
// }