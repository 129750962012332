// import { useState } from 'react';
import { Modal } from 'antd';
import axios from 'axios';
import { FR_KEY } from '../constants/contentMap';
import useStore from './useStore';

const FrK11Persistence = require('fr-k11-persistence');

// Override SDK API host
const ApiClient = new FrK11Persistence.ApiClient();
ApiClient.basePath = process.env.REACT_APP_PERS1_URL;

const api = new FrK11Persistence.DefaultApi(ApiClient)

const errorModal = (message) => {
    Modal.error({
        title: 'Error',
        content: message,
    });
}

function useApi({ subject, grade, modId }) {
    const subjectId = FR_KEY[subject];
    const gradeId = FR_KEY[grade];

    const [exercices, setExercices] = useStore([], 'exercices');
    const [paths, setPaths] = useStore([], 'paths');
    const [activities, setActivities] = useStore([], 'activities');
    const [modules, setModules] = useStore([], 'modules');
    const [allModules, setAllModules] = useStore([], 'allModules');
    const [allPaths, setAllPaths] = useStore([], 'allPaths');
    const [modeemplois, setModeemplois] = useStore([], 'modeemplois');

    const getData = async () => {

        let strapiToken = localStorage.getItem('strapi-token')
        console.log(strapiToken)

        let config = {
            headers: {
                Authorization: `Bearer ${strapiToken}`,
                'x-target': `${process.env.REACT_APP_STRAPI_ENDPOINT}/`

            }
        }

        const apiUrl = `${process.env.REACT_APP_CACHE}/`

        try {

            const exercicesData = await axios.get(apiUrl + "exercices?_limit=1000016", config)
            const pathsData = await axios.get(apiUrl + "paths?_limit=1000000", config)
            const modulesData = await axios.get(apiUrl + "modules?_limit=1000000", config)
            const activitiesData = await axios.get(apiUrl + "activities?_limit=1000002", config)
            const modeemploisData = await axios.get(apiUrl + "modeemplois?_limit=1000000", config)
            
            const exercices = exercicesData.data
                .filter(item =>
                    item.subject === subjectId &&
                    item.grade === gradeId &&
                    item.module === modId
                ).sort((a, b) => a.key - b.key);
            
            const activities = activitiesData.data
                .filter(item =>
                    item.subject === subjectId &&
                    item.grade === gradeId &&
                    item.module === modId
                ).map(item => {
                    let key = (item.path < 10) ? `0${item.path}` : item.path;
                    key += (item.activity < 10) ? `0${item.activity}` : item.activity;
                    item[key] = key;
                    return item
                }).sort((a, b) => parseInt(a.key) - parseInt(b.key))

            const paths = pathsData.data.filter(item =>
                item.subject === subjectId &&
                item.grade === gradeId &&
                item.module === modId
            );
            // Returns all paths available for subject/grade
            const allPaths = pathsData.data.filter(item =>
                item.subject === subjectId &&
                item.grade === gradeId
            ).sort((a, b) => a.module - b.module);

            // Returns module according to subject/grade/location ID
            const modules = modulesData.data.filter(item =>
                item.subject === subjectId &&
                item.grade === gradeId &&
                item.module === modId
            );

            // Returns all modules available for subject/grade
            const allModules = modulesData.data.filter(item =>
                item.subject === subjectId &&
                item.grade === gradeId
            ).sort((a, b) => a.module - b.module);

            const modeemplois = modeemploisData.data;

            setExercices(exercices);
            setActivities(activities);
            setPaths(paths);
            setAllPaths(allPaths);
            setModules(modules);
            setAllModules(allModules);
            setModeemplois(modeemplois);

            return {
                exercices,
                activities,
                paths,
                allPaths,
                modules,
                allModules,
                modeemplois,
            }

        } catch (error) {
            console.log(error);
            return error;
            // errorModal("Erreur de connection. Vérifiez votre connection");
            // throw Error(error)
        }
    }

    const createHistory = async (locale, subject, grade, moduleID, path, activity, sequence) =>{
        const history = {
            locale: locale,
            subject: subject,
            grade: grade,
            module: moduleID,
            path: path,
            activity: activity,
            sequence: JSON.stringify(sequence)
        }

        let authorization = "Bearer " + localStorage.getItem('hatier-token')
        console.log('In createHistory, JSON.stringify(history):', JSON.stringify(history));

        try {
            const res = await api.storeSequenceSequencePost(authorization, history)
            return res;
        } catch(error) {
            return error;
        }

    }

    const updateHistory = async (locale, subject, grade, moduleID, path, activity, sequence) => {
        const history = {
            locale: locale,
            subject: subject,
            grade: grade,
            module: moduleID,
            path: path,
            activity: activity,
            sequence: JSON.stringify(sequence)
        }

        let authorization = "Bearer " + localStorage.getItem('hatier-token')
        console.log('In updateHistory, JSON.stringify(history):', JSON.stringify(history));

        try {
            const res = await api.storeSequenceSequencePost(authorization, history)
            return res;
        } catch(error) {
            return error;
        }
    }

    const readHistory = async (locale, subject, grade, moduleID, path, activity) => {
        let authorization = "Bearer " + localStorage.getItem('hatier-token')

        try {
            const res = await api.obtainUniqueSequenceOr404SequenceGet(locale,
                subject, grade, moduleID, path, activity, authorization)
            return res;
        } catch(error){
            return error;
        }
    }

    const readHistoryAll = async (guidUser) =>{
        let authorization = "Bearer " + guidUser

        try {
            const res = await api.obtainMultipleSequencesSearchGet(authorization);
            return res;
        } catch(error){
            return error;
        }
    }

    const createTracking = async (locale, subject, grade, moduleID, path, activity, answers) =>{
        const content = {
            locale: locale,
            subject: subject,
            grade: grade,
            module: moduleID,
            path: path,
            activity: activity,
            tracking: answers
        }

        let token = localStorage.getItem('hatier-token');
        let authorization = "Bearer " + token;
        let formData = new FormData();
        formData.append('content', JSON.stringify(content))

        console.log('In createTracking JSON.stringify(content):', JSON.stringify(content));

        try {
            // Send tracking data to EVB backend
            content.tracking = JSON.stringify(answers);
            const post = await api.storeTrackingTrackingPost(authorization, content)
            return post;
        } catch (error) {
            return error;
        }
    }

    const updateTracking = async (locale, subject, grade, moduleID, path, activity, answers) =>{
        const content = {
            locale: locale,
            subject: subject,
            grade: grade,
            module: moduleID,
            path: path,
            activity: activity,
            tracking: answers
        }

        let token = localStorage.getItem('hatier-token');
        let authorization = "Bearer " + token;
        let formData = new FormData();
        formData.append('content', JSON.stringify(content))

        console.log('In createTracking JSON.stringify(content):', JSON.stringify(content));

        try {
            // Send tracking data to EVB backend
            content.tracking = JSON.stringify(answers);
            const post = await api.storeTrackingTrackingPost(authorization, content)
            return post;
        } catch (error) {
            return error;
        }
    }

    const readTracking = async (locale, subject, grade, moduleID, path, activity) => {
        let token = localStorage.getItem('hatier-token');
        let authorization = "Bearer " + token
        try {
            const get = await api.obtainUniqueTrackingOr404TrackingGet(locale, subject, grade, moduleID, path, activity, authorization) 
            return get;
        } catch (error) {
            return error;
        }
    }

    const readTrackingAll = async (guidUser) =>{
        let authorization = "Bearer " + guidUser
        try {
            const get = await api.obtainMultipleTrackingsSearchTrackingsGet(authorization)
            return get;
        } catch(error){
            return error;
        }
    }


    return {
        getData,
        exercices,
        paths,
        allPaths,
        modules,
        allModules,
        activities,
        modeemplois,
        readHistory,
        readHistoryAll,
        createHistory,
        updateHistory,
        readTracking,
        readTrackingAll,
        createTracking,
        updateTracking
    }

}

export default useApi;