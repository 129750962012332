import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import { StoreContext } from '../../containers/Store';

import Gallery from './Gallery';
import DashboardTeacher from './DashboardTeacher';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import Error404 from '../Error404';


export default () => {
  const {
    user: {
      user
    },
  } = useContext(StoreContext);

  if (user && user.roles.includes(ROLES.TEACHER)) {
    return (
      <Switch>
        <Route path={`/:subject/:grade/:modul/${ROUTES.DASHBOARD}`} render={() => <DashboardTeacher />}/>
        <Route
          exact
          path={`/:subject/:grade/:modul/${ROUTES.GALLERY}`}
          render={() => <Gallery />} />
        <Route component={Error404} />
      </Switch>
    )
  } else {
    console.log("IN CONTENT TEACHER, USER NOT TEACHER");
    return null
  }
}