import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ActivityDrawerStudent from '../../components/ActivityDrawerStudent';
import useParseLocation from '../../hooks/useParseLocation';

import { StoreContext } from '../../containers/Store';
import { Steps } from 'antd';

import "../../scss/PathStudent.scss"

const PathStudent = ({
  history,
}) => {
  const {
    data: {
      history: { activitiesState }
    },
    drawer: {
      setRightDrawer,
      setRightDrawerTitle,
      setStepId,
    },
    cms: {
      paths,
      activities
    },
  } = useContext(StoreContext);

  const { path: pathId } = useParseLocation();

  const [pathActivities, setPathActivities] = useState();
  useEffect(() => {
    if (activities && activities.length > 0) {
      const pathActivities = activities
        .filter(act => {
          return act.path === parseInt(pathId) && parseInt(pathId) !== 0
        })
        .sort((a, b) => a.activity - b.activity)

      setPathActivities(pathActivities);
    }
  }, [activities, pathId]);


  const extractData = (pathId, value) => {
    if (paths) {
      if (paths.filter(p =>
        p.path === parseInt(pathId)).length > 0) {
        return paths.filter(p =>
          p.path === parseInt(pathId))[0][value];
      } else {
        return ""
      }
    } else {
      return ""
    }
  }

  const Step = Steps.Step;

  return (
    <>
      {pathId > 0 &&
        <div>
          <h3>{"Parcours " + pathId.toString() + " : "}</h3>
          <p>{extractData(pathId, "description")}</p>
        </div>
      }
      <div className="path-container">
        <Steps direction="vertical" initial={0}>
          <Step
            title='Test diagnostique'
            style={{ cursor: 'pointer' }}
            status={(activitiesState && activitiesState[0]) ? "finish" : "process"}
            onClick={() => {
              setRightDrawerTitle('Test diagnostique');
              setRightDrawer(true);
              setStepId(0);
            }}
          />
          {
            pathActivities && pathActivities.map(act => (<Step
              title={`Activité ${act.activity}`}       
              description={act.student_objective}
              style={{ cursor: 'pointer' }}
              status={(activitiesState && activitiesState[pathId] && activitiesState[pathId][act.activity]) ? "finish" : "process"}
              onClick={() => {
                setRightDrawerTitle(`Activité ${act.activity}`);
                setRightDrawer(true);
                setStepId(act.activity);
              }}
            />))
          }
          <Step
            title='Test final'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setRightDrawerTitle('Test final');
              setRightDrawer(true);
              setStepId(pathActivities.length+1);
            }}
          />
          </Steps>
          <ActivityDrawerStudent  />
      </div>
    </>
  );
};

export default withRouter(PathStudent);