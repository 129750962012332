import React, { useContext, useEffect, useState } from 'react';

import { StoreContext } from '../../containers/Store';
import { Steps } from 'antd';

const PathTeacher = ({ pathId }) => {
  const {
    data: {
      indexes,
      dispatchIndexes,
      dispatchIA,
      setFinalScore,
    },
    drawer: {
      setRightDrawer,
      setRightDrawerTitle,
      setStepId,
      setReference,
      setPathId,
      clickCount,
      setClickCount
    },
    cms: {
      activities
    }
  } = useContext(StoreContext);

  const [pathActivities, setPathActivities] = useState();
  useEffect(() => {
    if (activities && activities.length > 0) {
      const pathActivities = activities
        .filter(act => {
          return act.path === pathId && pathId !== 0
        })
        .sort((a, b) => a.activity - b.activity)

      setPathActivities(pathActivities);
    }
  }, [activities, pathId]);

  const Step = Steps.Step;

  return (
    <div className="steps-container">
      <Steps direction="vertical" initial={0}>
        {
          pathActivities && pathActivities.map(act => (<Step
            title={act.name}       
            description={act.teacher_objective}
            style={{ cursor: 'pointer' }}
            status={"process"}
            onClick={() => {
              setClickCount(clickCount+1);
              setRightDrawerTitle(act.name);
              setRightDrawer(true);
              setStepId(act.activity);
              setReference([act.inspirations, act.sources]);
              setPathId(pathId);
              dispatchIndexes({
                type: 'SET_INDEXES',
                payload: {
                  ...indexes,
                  pathId,
                  activityId: act.activity,
                  exerciceId: 1,
                  contentId: 0,
                }})
              dispatchIA({ type: 'GAMELOOP', payload: {} })
            }}
          />))
        }
        <Step
          title='Test final'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setRightDrawerTitle('Test final');
            setRightDrawer(true);
            setPathId(pathId);
            setStepId(pathActivities.length+1);
            setFinalScore(0);
            dispatchIA({ type: 'GAMELOOP', payload: {}});
            dispatchIndexes({
              type: 'SET_INDEXES',
              payload: {
                ...indexes,
                pathId,
                activityId: pathActivities.length+1,
                exerciceId: 1,
                contentId: 0,
              }})
          }}
        />
        </Steps>
    </div>
  );
};

export default PathTeacher;