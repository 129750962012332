import React, { useEffect, useState } from 'react';

export const useError = (answers, lag, pathId, activityId) => {
  const nLastAnswers = (
    answers[pathId] &&
    answers[pathId][activityId] &&
    answers[pathId][activityId].length >= lag
  ) ? answers[pathId][activityId].slice(-lag) :
  [];
  // console.log('In Here nLastAnswers', nLastAnswers);
  const successiveErrors = nLastAnswers.reduce((acc, current, index) => {
    if (
      index === 0 ||
      current.exerciceId === nLastAnswers[index-1].exerciceId
    ) {
      acc += (current.isCorrect === false)
    }
    return acc;
  }, 0);
  // console.log('successiveErrors', successiveErrors);
  return successiveErrors;
}

export const useSuccess = (answers, lag, pathId, activityId) => {
  const nLastAnswers = (
    answers[pathId] &&
    answers[pathId][activityId] &&
    answers[pathId][activityId].length >= lag
  ) ? answers[pathId][activityId].slice(-lag) :
  [];
  // console.log('nLastAnswers', nLastAnswers);

  const successes = nLastAnswers.reduce((acc, current, index) => {
    if (
      index === 0 ||
      current.exerciceId !== nLastAnswers[index-1].exerciceId
    ) {
      acc += (current.isCorrect)
    }
    return acc;
  }, 0);

  // console.log('successes', successes);
  return successes;
}

// 4 sucesses in a row
// export const useSuccess = (answers, lag, pathId, activityId) => {

//   const [lastAnswers, dispatchLastAnswers] = useState([]);

//   useEffect(() => {
//     console.log('lastAnswers', lastAnswers);
//     if (answers[pathId] &&
//       answers[pathId][activityId] &&
//       answers[pathId][activityId].length >= lag
//     ) {
//       const lastA = answers[pathId][activityId].slice(-lag);
//       dispatchLastAnswers(lastA);
//     }
//   }, [answers, lag, pathId, activityId]);

//   const [successes, dispatchSuccesses] = useState(0);
//   useEffect(() => {
//     console.log('successive successes', successes);
//     if (successes === 4) {
//       dispatchSuccesses(0);
//     } else {
//       const s = lastAnswers.reduce((acc, current, index) => {
//         if (
//           index === 0 ||
//           current.exerciceId !== lastAnswers[index-1].exerciceId
//         ) {
//           acc += (current.isCorrect)
//         }
//         return acc;
//       }, 0);
//       dispatchSuccesses(s);
//     }
//   }, [lastAnswers]);

//   return successes;
// }