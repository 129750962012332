import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import { StoreContext } from '../../containers/Store';

import ChatbotStudent from './ChatbotStudent';
import PathStudent from './PathStudent';
import DashboardStudent from './DashboardStudent';

import IA from '../../containers/IA';
import GameLoop from '../../containers/GameLoop';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import Error404 from '../Error404';

export default () => {
  const {
    user: {
      user
    },
  } = useContext(StoreContext);

  if (user && user.roles.includes(ROLES.STUDENT)) {

    return (
      <IA>
        <GameLoop>
          <Switch>
            <Route exact path={`/:subject/:grade/:modul/:path/:activityId/${ROUTES.CHATBOT}`} render={() => <ChatbotStudent />} />
            <Route exact path={`/:subject/:grade/:modul/:path/:activityId/${ROUTES.PATH}`} render={() => <PathStudent />} />
            <Route exact path={`/:subject/:grade/:modul/:path/:activityId/${ROUTES.DASHBOARD}`} render={() => <DashboardStudent />} />
            <Route component={Error404} />
          </Switch>
        </GameLoop>
      </IA>
    )
  }
  return null
}