import React from 'react';
import { Affix } from 'antd';

import Navigation from './Navigation';
import Logo from './Logo';
import Footer from './Footer';
import "../scss/Layout.scss"

const LayoutContainer = ({ children, nomatch }) => {

  return (
    <div id="layout">
      <Affix>
        <div id="header">
          <Logo />
          {!nomatch && <Navigation />}
        </div>
      </Affix>
      
      <div id="content">
        {children}
      </div>
      <Footer />
    </div>
  );
}

export default LayoutContainer;