import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";

// import axios from 'axios';

import StudentImage from '../assets/images/student.png';
import TeacherImage from '../assets/images/teacher.png';

import { StoreContext } from '../containers/Store';

import * as ROLES from '../constants/roles';
import * as ROUTES from '../constants/routes';

import '../scss/Demo.scss';

const FrK11Auth = require('fr-k11-auth');
const ApiClient = new FrK11Auth.ApiClient();
ApiClient.basePath = process.env.REACT_APP_AUTH1_URL;
const api = new FrK11Auth.DefaultApi(ApiClient);
const secret = 'confinement';

const uuid = require('uuid/v4');


const callAPIDemoToken = function(userType) {

    const opts = (userType === ROLES.TEACHER) ?
        { 'classes': ["test"], 'classe': ''}
      : { 'classes': [], 'classe': 'test'}

    const userTypeApi = userType ? userType.toLowerCase() : '';
    return api.generateForgedTokenGenerateTokenGet(userTypeApi, 'demo', 'demo', 'demo', secret, opts);
}

export default () => {
    const { 
        user: {
            storeUser,
        },
        location: {
          subject,
          grade,
          modul
        }
      } = useContext(StoreContext);

    // const history = useHistory(); 
    
    const createStudentSession = () => {
        console.log('Set STUDENT')
        
        callAPIDemoToken(ROLES.STUDENT).then(
            data => {
                console.log('API called successfully. Returned data: ' + data);
                window.location = `/${subject}/${grade}/${modul}?token_itineraire=${data.token}`;
            },
            (error) => {
                console.log('Erreur : ', error);
            }
        )
    }
    const createTeacherSession = async () => {
        console.log('Set TEACHER')
        
        callAPIDemoToken(ROLES.TEACHER).then(
            data => {
                console.log('API called successfully. Returned data: ' + data);
                window.location = `/${subject}/${grade}/${modul}?token_itineraire=${data.token}`;
            },
            (error) => {
                console.log('Erreur : ', error);
            }
        )
        // authUser.roles = [ROLES.TEACHER];
        // storeUser(authUser);

    }

    return (
        <div className="demo-container">
            <br />
            <div>
                <h1> Version gratuite </h1>
            </div>
            <br />
            <div>
                <h2> Choisissez un type de connexion </h2>
            </div>
            <br />
            <div className="users-container">
            <div key='student' onClick={createStudentSession} >
                <div className="user-item">
                    <h3> Elève </h3>
                    <img src={StudentImage} />
                </div>
            </div>
            <div key='teacher' onClick={createTeacherSession}>
                <div className="user-item">
                    <h3> Enseignant </h3>
                    <img src={TeacherImage} />
                </div>
            </div>
            </div>
        </div>
    )
}
