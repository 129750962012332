import React from 'react';

import '../scss/ErrorBanner.scss';

function ErrorBanner(props) {
    const defaultMessage = "Une erreur s'est produite";
    return (
      <div className="errorBanner">
          { props.message ? props.message : defaultMessage}
      </div>
    )
}

export default ErrorBanner;