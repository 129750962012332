import React, { useState } from 'react'
import uniqid from 'uniqid';
import { toBold, toItalic } from '../utils/stringOperations'

import '../scss/Qcm.scss'

function Qcm({ content, getAnswer, type}) {

    const [indexes, setIndexes] = useState([])
    const [answers, setAnswers] = useState([])

    const onRadioChange = (e) => {
        getAnswer( content[e.target.value], e.target.value, "QCU"); 
    }
    const onCheckChange = (e) => {

        let isInIndexes = indexes.findIndex( el => el === parseInt( e.currentTarget.value ) + 1 )
        
        let arrIndexes = indexes
        let arrAnswsers = answers

        if( isInIndexes === -1 ){
            arrIndexes.push( parseInt(e.currentTarget.value) + 1 )
            arrAnswsers.push(content[e.currentTarget.value])
        }else{
            arrIndexes.splice(isInIndexes, 1)
            arrAnswsers.splice(isInIndexes, 1)
        }
        setIndexes(arrIndexes)
        setAnswers(arrAnswsers.sort())
    }

    const validate = (e) =>{
        e.preventDefault()
        getAnswer( answers, indexes, type );
    }

    const formatText = (choice) =>{
        const messageWithBold = toBold(choice);
        const messageClean = (typeof messageWithBold === 'string') ? toItalic(messageWithBold) : messageWithBold.map(frag => (typeof frag === 'string') && toItalic(frag) || frag);
        return messageClean
    }

    const singleChoice =
        content.map( ( choice, i ) => (
            <div className="qcm-item" key={ uniqid() }>
                <input id={"q" + i} name="qcm" type="radio" value={i} onChange={ onRadioChange } />
                <label htmlFor={"q" + i} >{ formatText(choice) }</label>
            </div>
        ))

        const multipleChoices =
        <>
            { content.map( ( choice, i ) => (
                <div className="qcm-item" key={ uniqid() }>
                    <input id={"q" + i} type="checkbox" value={i} onChange={ onCheckChange } />
                    <label htmlFor={"q" + i} >{ formatText(choice) }</label>
                </div>
            )) }
            <button className="btn btn-validate" onClick={validate}>Valider</button>
        </>

    return (
        <div className="qcm-container">
            <form>
                { type === "QCMET" || type === "QCMOU" ? multipleChoices : singleChoice }
            </form>
        </div>
    );
}

export default Qcm
