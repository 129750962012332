import React, { useContext, useState, useEffect, useRef, createRef } from 'react';
import { StoreContext } from '../containers/Store';
import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';
import { useLocation, NavLink, Link } from 'react-router-dom';
import { Icon } from 'antd';
import useParseLocation from '../hooks/useParseLocation';
import useApi from '../hooks/useApi';

import '../scss/Navigation.scss';


const Navigation = () => {
  const {
    user: { 
      user: authUser
    }
  } = useContext(StoreContext);

  const GUIDUser = localStorage.getItem('hatier-token');

  const { allModules, allPaths, readHistoryAll } = useApi({
    subject,
    grade
  });

  let location = useLocation();
  const { subject, grade, modul } = useParseLocation();
  const currentUrl ='/' + subject + '/' + grade + '/' + modul ;

  const [ showMenu, setShowMenu ] = useState(false);
  const [ showNav, setShowNav ] = useState(false);
  const [ targetHistory, setTargetHistory ] = useState([]);

  useEffect(() => {
    readHistoryAll(
      GUIDUser
    ).then((res) => {
      setTargetHistory(res.results);
    }).catch((error) => {
      console.log('readHistory catch', error);
    })
  }, [])
  
  const sortHistory = (modId) => {
    return targetHistory.filter(act =>
      act.module === modId
    )
    .sort((a, b) => b.activity - a.activity)
    .sort((a, b) => b.path - a.path)
  }

  /* QUICKFIX to recalculate path if user has disconnected during first activity after test */
  const computePathFromTestConclusion = (seq, modId) => {
    const testConclusion = seq[seq.length-2].content;
    const attributedPath = allPaths.filter(p => {
      return (p.module === modId &&
      testConclusion.trim() === p.difficulty_student.trim())
    })[0];
    return (attributedPath) ? attributedPath.path : 1 // should not happen, but should it happen, path1
  }

  const getModuleUrl = (modId) => {
    let historyForTarget = sortHistory(modId);
    let targetUrl;

    if (authUser.roles.includes(ROLES.STUDENT)) {
      // If student has already began clicked module, send him to right path/activity
      if (historyForTarget.length > 0) {
        let lastPath = historyForTarget[0].path;
        let lastActivity = historyForTarget[0].activity;

        // If student has only done the test, retrieve ia.action path recorded in sequence
        if (lastPath === 0 && lastActivity === 0 && historyForTarget.length === 1) {
          let sequence = JSON.parse(historyForTarget[0].sequence);
          let iaPath = sequence.find(obj => Object.keys(obj).find(property => property === "next_path"));
          iaPath ? lastPath = iaPath.next_path : lastPath = computePathFromTestConclusion(sequence, modId);
          lastActivity = 1;
        }

        targetUrl = '/'+ subject +'/'+ grade +'/module' + modId +'/'+ lastPath +'/'+ lastActivity +'/parcours';
      } else {
        targetUrl = '/'+ subject +'/'+ grade +'/module'+ modId +'/0/0/chatbot';
      }
    } else {
      targetUrl = '/'+ subject +'/'+ grade +'/module'+ modId +'/galerie';
    }

    window.location.replace(targetUrl);
  };

  const getCurrentModuleName = () => {
    if (allModules) {
      const result = allModules.filter(element => element.module === parseInt(modul.split('module')[1]));
      if (result[0]) {
        return result[0].name;
      } else {
        return "Module";
      }
    }
  }

  // Handle side menu closing when resizing
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1194) {
        setShowNav(false);
        setShowMenu(false);
      } else {
        setShowNav(true);
        setShowMenu(true);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  })

  const openNav = () => {
    setShowNav(true);
    navRef.current.className = "menu-container open";
  }

  const closeNav = () => {
    setShowNav(false);
    setShowMenu(false);
    navRef.current.className = "menu-container";
  }

  const navRef = useRef(null);
  const menuRef = createRef(null);

  const studentLogout = window.redirectToHatier ? window.redirectToHatier : ROUTES.LOGOUT;
  const teacherLogout = window.redirectToHatier ? window.redirectToHatier : currentUrl + '/logout';

  const studentNav =
    <>
      <div className="mobile-menu-open" onClick={openNav}>
        <Icon type="menu" />
      </div>
      <div className="menu-container" ref={navRef}>
        <div className="mobile-menu-close" onClick={closeNav}>
          <Icon type="close" />
        </div>
        {/* <div className="nav-item">
          <NavLink to="#" exact onClick={() => setShowMenu(!showMenu)}>
            <Icon type="appstore" />
            <span>{ getCurrentModuleName() }</span>
            <Icon type={showMenu ? "up" : "down"} />
          </NavLink>
        </div> */}
        <div className="nav-item">
          <NavLink 
            to={ROUTES.CHATBOT} 
            activeClassName='selected'
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
          >
              <Icon type="robot" />
              <span>{ getCurrentModuleName() }</span>
              <Icon type={showMenu ? "up" : "down"} />
          </NavLink>
          {showMenu && showNav && 
            <div 
              className="modules-list-side"
              onMouseEnter={() => setShowMenu(true)}
              onMouseLeave={() => setShowMenu(false)}
            >
              {allModules && allModules.map((module, i) => {
                return (
                  <div key={i} className="module-item">
                    <Link 
                      to="#"
                      onClick={() => getModuleUrl(module.module)}
                    >
                      {"M"+ module.module +" - "+ module.name}
                    </Link>
                  </div>
                )
              })}
            </div>
          }
        </div>
        <div className="nav-item">
          <NavLink to={ROUTES.PATH} activeClassName='selected'>
            <Icon type="rise" />
            <span> Parcours </span>
          </NavLink>
        </div>
        <div className="nav-item">
          <NavLink to={ROUTES.DASHBOARD} activeClassName='selected'>
            <Icon type="dashboard" />
            <span> Tableau de bord </span>
          </NavLink>
        </div>
        <div className="nav-item btn-logout">
          <Link to={studentLogout}>
            <Icon type="poweroff" />
            <span>Se déconnecter</span>
          </Link>
        </div>
      </div>
    </>

  const teacherNav =
    <>
      <div className="mobile-menu-open" onClick={openNav}>
        <Icon type="menu" />
      </div>
      <div className="menu-container" ref={navRef}>
        <div className="mobile-menu-close" onClick={closeNav}>
          <Icon type="close" />
        </div>
        {/* <div className="nav-item">
          <NavLink to={currentUrl + '/' + ROUTES.GALLERY} activeClassName='selected'>
            <Icon type="rise" />
            <span> Plan du module </span>
          </NavLink>
        </div> */}
                <div className="nav-item">
          <NavLink 
            to={currentUrl + '/' + ROUTES.GALLERY}
            activeClassName='selected'
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
          >
              <Icon type="robot" />
              <span>{ getCurrentModuleName() }</span>
              <Icon type={showMenu ? "up" : "down"} />
          </NavLink>
          {showMenu && showNav && 
            <div 
              className="modules-list-side"
              onMouseEnter={() => setShowMenu(true)}
              onMouseLeave={() => setShowMenu(false)}
            >
              {allModules && allModules.map((module, i) => {
                return (
                  <div key={i} className="module-item">
                    <Link 
                      to="#"
                      onClick={() => getModuleUrl(module.module)}
                    >
                      {"M"+ module.module +" - "+ module.name}
                    </Link>
                  </div>
                )
              })}
            </div>
          }
        </div>
        <div className="nav-item">
          <NavLink to={currentUrl + '/' + ROUTES.DASHBOARD} activeClassName='selected'>
            <Icon type="dashboard" />
            <span> Tableau de bord </span>
          </NavLink>
        </div>
        <div className="nav-item btn-logout">
          <Link to={teacherLogout}>
            <Icon type="poweroff" />
            <span>Se déconnecter</span>
          </Link>
        </div>
      </div>
    </>


  if (location.pathname.includes(ROUTES.DEMO)) {
    return null
  } else {
    if (authUser) {
      if (authUser.roles.includes(ROLES.STUDENT)) {
        return (
          <>
            <nav id="main-nav">
              {studentNav}
            </nav>
            <div className="break"></div>
            {showMenu && !showNav &&
              <div 
                className="modules-list"
                ref={menuRef}
                onMouseEnter={() => setShowMenu(true)}
                onMouseLeave={() => setShowMenu(false)}
              >
                {allModules && allModules.map((module, i) => {
                  return (
                    <div key={i} className="module-item">
                      <Link 
                        to="#"
                        onClick={() => getModuleUrl(module.module)}
                      >
                        {"M"+ module.module +" - "+ module.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
            }
          </>
        )
      } else if (authUser.roles.includes(ROLES.TEACHER)) {
        return (
          <>
            <nav id="main-nav">
              {teacherNav}
            </nav>
            <div className="break"></div>
            {showMenu && !showNav &&
              <div 
                className="modules-list"
                ref={menuRef}
                onMouseEnter={() => setShowMenu(true)}
                onMouseLeave={() => setShowMenu(false)}
              >
                {allModules && allModules.map((module, i) => {
                  return (
                    <div key={i} className="module-item">
                      <Link 
                        to="#"
                        onClick={() => getModuleUrl(module.module)}
                      >
                        {"M"+ module.module +" - "+ module.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
            }
          </>
        )
      } else {
        return null
      }
    }
  }
}

export default Navigation
