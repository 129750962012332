import React from 'react'
import { Button } from 'antd';

import '../scss/ButtonAction.scss'

function ButtonAction({ text, onClick }) {
    return (
        <>
            <Button className="btn-action"
                onClick={onClick}
                type="primary"
                size="large"
            >
                {text}
            </Button>
        </>
    )
}

export default ButtonAction
