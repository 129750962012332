import React from 'react';

import { Row, Col } from 'antd';


const page404 = () => {
  return (
    <div className="centered-container">
      <div>
          <h1 style={{ fontSize: 80 }}>
            Erreur 404
          </h1>
          <h2>
            Cette page n'existe pas.
            Avez-vous la bonne adresse ?
          </h2>
          <br />
          <br />
          <iframe src="https://giphy.com/embed/kBlPua2g1f66PGjT7w" width="480" height="380" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
      </div>
    </div>
  );
};

export default page404;
