import React from 'react'
import { toBold, toItalic, toNewline } from '../utils/stringOperations'

import '../scss/Message.scss'

function Message({ message }) {

    const withBold = (input) => {
        if (typeof input === 'string') {
            return toBold(input)
        } else if (input.length) {
            return input.map(frag => withBold(frag));
        } else {
            return input
        }
    }

    const withItalic = (input) => {
        if (typeof input === 'string') {
            return toItalic(input)
        } else if (input.length) {
            return input.map(frag => withItalic(frag));
        } else {
            return input
        }
    }

    const withNewline = (input) => {
        if (typeof input === 'string') {
            return toNewline(input)
        } else if (input.length) {
            return input.map(frag => withNewline(frag));
        } else {
            return input
        }
    }

    const messageClean = withNewline(withItalic(withBold(message)));


    return (
        <div
            className={(message.includes('📖')) ? 'instruction-bubble' : 'message-bubble'}
        >
            <div>
                {messageClean}
            </div>
            
    
        </div>
    )

}

export default Message
