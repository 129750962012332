export const errorsInBounds = (answers, activityId, lowerBound, upperBound) => {
  return answers.filter(answer =>
    answer.activity === activityId &&
    answer.exercice >= lowerBound &&
    answer.exercice < upperBound &&
    answer.correct_answer !== "" &&
    answer.correct_answer !== null &&
    answer.isCorrect === false).length;
};

export const correctAnswersInARow = (answers, numberInARow) => {
  const newAnswers = answers.length > numberInARow ? answers.slice(-numberInARow) : answers;
  const isNSuccessesInARow = newAnswers.filter(answer =>
    answer.isCorrect).length === numberInARow;
  const noContinue = newAnswers.filter(answer =>
    answer.content === 'Continuer').length === 0;
  return isNSuccessesInARow && noContinue;
};

export const errorsInExercice = (answers) => {
  const exercice = answers[answers.length-1].exercice;
  const activity = answers[answers.length-1].activity;
  return answers.filter(answer =>
    answer.activity === activity &&
    answer.exercice === exercice &&
    answer.isCorrect === false).length;
}

export const randomAnswers = (answers) => {
  const base = answers.filter(answer =>
    answer.activity > 0 &&
    answer.exercice > 0 &&
    (
      answer.isCorrect === true ||
      answer.isCorrect === false
    )
  );
  const nbExercices = base.length;
  // nb of exercices (not unique) : base.length
  const nbOptions = base.reduce((acc, current) => {
    if (current.activity === 1) {
      acc += 1 * 0.5
    } else if (current.activity > 1) {
      acc += 1 * 0.25
    }
    return acc;
  }, 0);
  return nbOptions;
}

export const numberConsecutiveSuccesses = (answers) => {
  const errors = answers
    .filter(answer =>
      answer.activity > 0 &&
      answer.exercice > 0 &&
      (
        answer.isCorrect === true ||
        answer.isCorrect === false
      )
    )
    .map((a, i) => {
      if (a.isCorrect === false) {
        return i
      }
    })
    .filter(a => !!a);
  const base = answers
    .filter(answer =>
      answer.activity > 0 &&
      answer.exercice > 0 &&
      (
        answer.isCorrect === true ||
        answer.isCorrect === false
      )
    );
  if (errors.length === 0 && base.length > 0) return base.length;
  return errors.reduce((acc, current, i, array) => {
    if (i === 0 && array[i] === 1) {
      acc = array[i]-1;
    } else if (i === 0) {
      acc = array[i];
    }
    if (i < array.length-1) {
      const temp = array[i+1]-current-1;
      if (temp > acc) {
        acc = temp;
      }
    }
    return acc;
  }, 0); 
}


