import React from 'react'
import { toBold, toItalic } from '../utils/stringOperations'

import '../scss/Answer.scss'

function Answer({ answer }) {
    /* console.log("answer",answer); */

    const messageWithBold = (typeof answer === "object") ? answer : toBold(answer);
    const messageClean = (typeof messageWithBold === 'string') ? toItalic(messageWithBold) :
        messageWithBold.map(frag => (typeof frag === 'string') && toItalic(frag) || frag);


    return (
        <div className='message-bubble right'>
            <div>
                {messageClean}
            </div>
        </div>
    )
}

export default Answer
